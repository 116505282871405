import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { Drawer as RawDrawer } from 'antd';

type Props = ComponentProps<typeof RawDrawer>;

let StyledDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }
`;

function Drawer(props: Props) {
  return <RawDrawer {...props} />;
}

export default StyledDrawer;
