import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function useTranslation() {
  let { languageChosen, translations } = useSelector((state) => state.i18n);

  let t = useCallback(
    (key: string) => {
      let translated = key;
      if (languageChosen) {
        translated = translations[languageChosen][key] || key;
      }
      return translated;
    },
    [languageChosen, translations],
  );

  return t;
}
