import React, { useState, useMemo, createContext } from 'react';

export let ClientContext = createContext<ContextValue>({
  selectedClientID: null,
  selectedProgrammeID: null,
  selectedRegionID: null,
  selectedProjectID: null,
  selectedCertificationID: null,
  selectClientID: () => {},
  selectProgrammeID: () => {},
  selectRegionID: () => {},
  selectProjectID: () => {},
  selectCertificationID: () => {},
});

export type ContextValue = {
  selectedClientID: Maybe<ID>;
  selectedProgrammeID: Maybe<ID>;
  selectedRegionID: Maybe<ID>;
  selectedProjectID: Maybe<ID>;
  selectedCertificationID: Maybe<ID>;
  selectClientID: React.Dispatch<React.SetStateAction<SelectedClient>>;
  selectProgrammeID: React.Dispatch<React.SetStateAction<SelectedProgramme>>;
  selectRegionID: React.Dispatch<React.SetStateAction<SelectedRegion>>;
  selectProjectID: React.Dispatch<React.SetStateAction<SelectedProject>>;
  selectCertificationID: React.Dispatch<
    React.SetStateAction<SelectedCertification>
  >;
};
type SelectedClient = Maybe<ID>;
type SelectedProgramme = Maybe<ID>;
type SelectedRegion = Maybe<ID>;
type SelectedProject = Maybe<ID>;
type SelectedCertification = Maybe<ID>;

export default function ClientProvider({ children }) {
  let [selectedClientID, selectClientID] = useState<SelectedClient>(undefined);
  let [selectedProgrammeID, selectProgrammeID] = useState<SelectedProgramme>(
    undefined,
  );
  let [selectedRegionID, selectRegionID] = useState<SelectedRegion>(undefined);
  let [selectedProjectID, selectProjectID] = useState<SelectedProject>(
    undefined,
  );

  let [selectedCertificationID, selectCertificationID] = useState<
    SelectedCertification
  >(undefined);

  let contextValue = useMemo(() => {
    return {
      selectedClientID,
      selectedProgrammeID,
      selectedRegionID,
      selectedProjectID,
      selectedCertificationID,
      selectClientID,
      selectProgrammeID,
      selectRegionID,
      selectProjectID,
      selectCertificationID,
    };
  }, [
    selectedClientID,
    selectedProgrammeID,
    selectedRegionID,
    selectedProjectID,
    selectedCertificationID,
  ]);

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
}
