import React, { forwardRef, ComponentProps } from 'react';

import { Input as RawInput } from 'antd';

type Props = ComponentProps<typeof RawInput>;

function Input(props: Props, ref: React.Ref<any>) {
  return <RawInput ref={ref} {...props} />;
}

export default forwardRef(Input);
