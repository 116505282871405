import React, { ReactNode, ComponentProps } from 'react';

import { Select as RawSelect } from 'antd';

type Props = { children: ReactNode } & ComponentProps<typeof RawSelect>;

export default function Select(props: Props) {
  return <RawSelect {...props} />;
}

Select.OptGroup = RawSelect.OptGroup;
Select.Option = RawSelect.Option;
