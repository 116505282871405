import React, { forwardRef, ComponentProps } from 'react';

import { Input as RawInput } from 'antd';

type Props = ComponentProps<typeof RawInput.TextArea>;

function TextArea(props: Props, ref: React.Ref<any>) {
  return <RawInput.TextArea ref={ref} {...props} />;
}

export default forwardRef(TextArea);
