import React, { ComponentProps } from 'react';
import { Descriptions as RawDescriptions } from 'antd';

type Props = ComponentProps<typeof RawDescriptions>;

export default function Descriptions(props: Props) {
  return <RawDescriptions {...props} />;
}

Descriptions.Item = RawDescriptions.Item;
