import React, { ReactNode, CSSProperties as StyleSheet } from 'react';
import styled, { CSSProperties } from 'styled-components';

let RawView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;

type Props = {
  style?: CSSProperties | StyleSheet;
  children?: ReactNode;
  column: number;
};

function ViewGrid({ children, ...otherProps }: Props) {
  return <RawView {...otherProps}>{children}</RawView>;
}

export default ViewGrid;
