import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

const FONT_SIZE = {
  xxxLarge: '2.5rem',
  xxLarge: '2rem',
  xLarge: '1.5rem',
  large: '1.4rem',
  medium: '1.3rem',
  small: '1.2rem',
  xSmall: '1.1rem',
  xxSmall: '1rem',
};

type FontSize =
  | 'xxxLarge'
  | 'xxLarge'
  | 'xLarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xSmall'
  | 'xxSmall';

type Props = {
  italic?: boolean;
  sup?: string;
  link?: boolean;
  color?: string;
  size?: FontSize;
  bold?: boolean;
  weight?: string;
  style?: CSSProperties;
  children?: ReactNode;
  title?: string;
  id?: string;
};

let StyledText = styled.span`
  font-smooth: auto;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: ${(props: Props) => FONT_SIZE[props.size || 'small']};
  font-weight: ${(props: Props) => {
    if (props.weight) {
      return props.weight;
    }
    return props.bold ? '600' : '400';
  }};
  color: ${(props: Props) => props.color};
  font-style: ${(props: Props) => (props.italic ? 'italic' : 'normal')};
`;

let StyledLink = styled.a`
  font-smooth: auto;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: ${(props: Props) => FONT_SIZE[props.size || 'small']};
  font-weight: ${(props: Props) => {
    if (props.weight) {
      return props.weight;
    }
    return props.bold ? '600' : '400';
  }};
  color: ${(props: Props) => props.color};
  font-style: ${(props: Props) => (props.italic ? 'italic' : 'normal')};
`;

function Text({ link, children, sup, ...otherProps }: Props) {
  return link ? (
    <StyledLink {...otherProps}>
      {children}
      <sup>{sup}</sup>
    </StyledLink>
  ) : (
    <StyledText {...otherProps}>
      {children}
      <sup>{sup}</sup>
    </StyledText>
  );
}

export default Text;
