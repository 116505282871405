import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  index,
  moveRow,
  record,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item, index, record);
    },
  });
  const [, drag] = useDrag({
    item: { type, index, costCategoryID: restProps['data-row-key'] },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      let isFooter =
        record?.isTotal ||
        record?.isContigency ||
        record?.isTotalBudget ||
        record?.isRiskItemSubtotal ||
        record?.isSubtotal;
      return !isFooter;
    },
  });
  drop(drag(ref));
  return (
    <tr
      //@ts-ignore
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DragableBodyRow;
