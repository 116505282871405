export default function debounce(fn, delay = 200 /* in milisecond */) {
  let timerId;

  return function() {
    const args = Array.prototype.slice.call(arguments);

    if (timerId) {
      // Clear previous timer before creating new one for new data
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      clearTimeout(timerId);
      fn(...args);
    }, delay);
  };
}
