import React, { ComponentProps } from 'react';
import { Radio as RawRadio } from 'antd';

type Props = ComponentProps<typeof RawRadio>;

export default function Radio(props: Props) {
  return <RawRadio {...props} />;
}

Radio.Group = RawRadio.Group;
Radio.Button = RawRadio.Button;
