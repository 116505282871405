import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { Text, View } from '.';

export default function MessageContent({ children }) {
  return (
    <View
      style={{ display: 'inline-flex', flexDirection: 'row', maxWidth: 900 }}
    >
      {' '}
      <Text size="xxSmall">{children}</Text>
      <CloseOutlined
        onClick={() => message.destroy()}
        style={{
          marginRight: 0,
          marginLeft: 8,
          color: '#00002d',
          lineHeight: 1.5,
        }} />
    </View>
  );
}
