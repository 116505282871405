import React, { ReactNode, ComponentProps } from 'react';

import { Tree as RawTree } from 'antd';

type Props = ComponentProps<typeof RawTree> & {
  children?: ReactNode;
};

export default function Tree(props: Props) {
  return <RawTree {...props} />;
}

Tree.TreeNode = RawTree.TreeNode;
