import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/client';
import { ApolloLink, from } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import { message, notification } from 'antd';

import AppRoute from './routes/AppRoute';
import IdleTimer from './components/IdleTimer';

message.config({
  duration: 2,
  maxCount: 1,
});

notification.config({
  duration: 2,
});

const { REACT_APP_BACKEND_URL } = process.env;
let httpLink = new HttpLink({ uri: `${REACT_APP_BACKEND_URL}/graphqlx` });
let restLink = new RestLink({ uri: `${REACT_APP_BACKEND_URL}/api` });

let authMiddleware = new ApolloLink((operation, forward) => {
  let session = localStorage.getItem('session');
  let token = session ? JSON.parse(session)?.token : null;
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

let client = new ApolloClient({
  link: from([authMiddleware, restLink, httpLink]),
  cache: new InMemoryCache(),
});

let App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <IdleTimer />
        <AppRoute />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
