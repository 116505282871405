import React, { useRef, useEffect } from 'react';

function useOutsideAlerter(ref, onClickAway) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

type Props = {
  onClickAway: () => void;
  children: React.ReactElement;
};

export default function ClickAwayListener({ onClickAway, children }: Props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickAway);

  return <div ref={wrapperRef}>{children}</div>;
}
