import React from 'react';

import { Result } from '../';

export default function NotFound() {
  return (
    <Result
      status="404"
      title="Page not found!"
      subTitle="Make sure you type the correct URL"
    />
  );
}
