import React, { lazy } from 'react';

import Login from '../screens/Login/Login';
import * as Resources from '../components/AccessControl/utils/pageResources';

const MODULES = {
  Dashboard: lazy(() => import('../screens/Dashboard')),
  Profile: {
    Profile: lazy(() => import('../screens/Dashboard/Profile/Profile')),
    Setting: lazy(() => import('../screens/Dashboard/Settings')),
  },
  Project: {
    ProjectDetails: lazy(() =>
      import('../screens/Dashboard/Project/ProjectDetails/ProjectDetails'),
    ),
    Cashflows: lazy(() =>
      import('../screens/Dashboard/Project/Cashflows/Cashflows'),
    ),
    Milestones: lazy(() =>
      import('../screens/Dashboard/Project/Milestones/Milestones'),
    ),
    DocumentRegistry: lazy(() =>
      import('../screens/Dashboard/Project/DocumentRegistry/DocumentRegistry'),
    ),
    Contacts: lazy(() =>
      import('../screens/Dashboard/Project/Contacts/Contacts'),
    ),
    DocumentManagement: lazy(() =>
      import(
        '../screens/Dashboard/Project/DocumentManagement/DocumentManagement'
      ),
    ),
    Photos: lazy(() => import('../screens/Dashboard/Project/Photos/Photos')),
    Reminders: lazy(() =>
      import('../screens/Dashboard/Project/Reminders/Reminders'),
    ),
    ProjectClosure: lazy(() =>
      import('../screens/Dashboard/Project/ProjectClosure/ProjectClosure'),
    ),
  },
  Certification: {
    HistoricalCertification: lazy(() =>
      import(
        '../screens/Dashboard/Certifications/HistoricalCertifications/HistoricalCertifications'
      ),
    ),
    BudgetSummaries: lazy(() =>
      import(
        '../screens/Dashboard/Certifications/BudgetSummaries/BudgetSummaries'
      ),
    ),
    CostSummaries: lazy(() =>
      import('../screens/Dashboard/Certifications/CostSummaries/CostSummaries'),
    ),
    UploadCertification: lazy(() =>
      import(
        '../screens/Dashboard/Certifications/UploadCertification/UploadCertification'
      ),
    ),
  },
  Assessment: {
    WorkProgressAssessment: lazy(() =>
      import(
        '../screens/Dashboard/Assessments/WorkProgressAssessment/WorkProgressAssessment'
      ),
    ),
    Verification: lazy(() =>
      import('../screens/Dashboard/Assessments/Verification/Verification'),
    ),
  },
  Report: lazy(() => import('../screens/Dashboard/ReportIframe')),
  Admin: {
    Projects: lazy(() =>
      import('../screens/Dashboard/Admin/Projects/Projects'),
    ),
    Regions: lazy(() => import('../screens/Dashboard/Admin/Regions/Regions')),
    Users: lazy(() => import('../screens/Dashboard/Admin/Users/Users')),
    Clients: lazy(() => import('../screens/Dashboard/Admin/Clients/Clients')),
    Roles: lazy(() => import('../screens/Dashboard/Admin/Roles/Roles')),
    Licensees: lazy(() =>
      import('../screens/Dashboard/Admin/Licensees/Licensees'),
    ),
    Resources: lazy(() =>
      import('../screens/Dashboard/Admin/Resources/Resources'),
    ),
    FunctionalSpace: lazy(() =>
      import('../screens/Dashboard/Admin/FunctionalSpaces/FunctionalSpaces'),
    ),
    CostCategories: lazy(() =>
      import('../screens/Dashboard/Admin/CostCategorySchedule/CostCategories'),
    ),
    Programmes: lazy(() =>
      import('../screens/Dashboard/Admin/Programmes/Programmes'),
    ),
    BuildingTypes: lazy(() =>
      import('../screens/Dashboard/Admin/BuildingTypes/BuildingTypes'),
    ),
    ClientDocuments: lazy(() =>
      import(
        '../screens/Dashboard/Admin/ClientDocumentLibrary/ClientDocumentLibrary'
      ),
    ),
    Permission: lazy(() =>
      import('../screens/Dashboard/Admin/Permissions/Permissions'),
    ),
  },
};

const PUBLIC_PAGES = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  // {
  //   path: '*',
  //   exact: false,
  //   component: () => {
  //     return (
  //       <View style={{ justifyContent: 'center', flex: 1 }}>
  //         <Result
  //           status="404"
  //           title="Page not found!"
  //           subTitle="Make sure you type the correct URL"
  //         />
  //       </View>
  //     );
  //   },
  // },
];

const DASHBOARD_PAGES = [
  {
    path: '/dashboard',
    exact: true,
    component: MODULES.Dashboard,
  },
  //Profile
  {
    path: '/profile',
    exact: true,
    component: MODULES.Profile.Profile,
  },
  {
    path: '/settings',
    exact: true,
    component: MODULES.Profile.Setting,
  },
  // Project
  {
    path: '/project-details',
    exact: true,
    component: MODULES.Project.ProjectDetails,
    resource: Resources.ProjectDetailPage,
  },
  {
    path: '/cashflows',
    exact: true,
    component: MODULES.Project.Cashflows,
    resource: Resources.CashflowListPage,
  },
  {
    path: '/milestones',
    exact: true,
    component: MODULES.Project.Milestones,
    resource: Resources.MilestoneListPage,
  },
  {
    path: '/project-document-registry',
    exact: true,
    component: MODULES.Project.DocumentRegistry,
    resource: Resources.KeyDocumentMappingPage,
  },
  {
    path: '/contacts',
    exact: true,
    component: MODULES.Project.Contacts,
    resource: Resources.ContactListPage,
  },
  {
    path: '/document-management',
    exact: true,
    component: MODULES.Project.DocumentManagement,
    resource: Resources.RelatedDocumentListPage,
  },
  {
    path: '/photos',
    exact: true,
    component: MODULES.Project.Photos,
    resource: Resources.PhotoListPage,
  },
  {
    path: '/reminders',
    exact: true,
    component: MODULES.Project.Reminders,
    resource: Resources.ReminderListPage,
  },
  {
    path: '/project-closure',
    exact: true,
    component: MODULES.Project.ProjectClosure,
    resource: Resources.ProjectClosurePage,
  },
  // Certifications
  {
    path: '/budget-summaries',
    exact: true,
    component: MODULES.Certification.BudgetSummaries,
    resource: Resources.BudgetSummariesPage,
  },
  {
    path: '/cost-summaries',
    exact: true,
    component: MODULES.Certification.CostSummaries,
    resource: Resources.CostCategoryListPage,
  },
  {
    path: '/cost-summaries/:costSummaryID',
    exact: true,
    component: MODULES.Certification.CostSummaries,
  },
  {
    path: '/historical-certifications',
    exact: true,
    component: MODULES.Certification.HistoricalCertification,
    resource: Resources.CertificationListPage,
  },
  {
    path: '/upload-certifications',
    exact: true,
    component: MODULES.Certification.UploadCertification,
    resource: Resources.UploadCertificationPage,
  },
  // Assessments
  {
    path: '/work-progress-assessment',
    exact: true,
    component: MODULES.Assessment.WorkProgressAssessment,
    resource: Resources.WorkProgressAssessmentPage,
  },
  {
    path: '/verification',
    exact: true,
    component: MODULES.Assessment.Verification,
    resource: Resources.VerificationPage,
  },
  // Admin
  {
    path: '/projects',
    exact: true,
    component: MODULES.Admin.Projects,
    resource: Resources.ProjectListPage,
  },
  {
    path: '/regions',
    exact: true,
    component: MODULES.Admin.Regions,
    resource: Resources.RegionListPage,
  },
  {
    path: '/users',
    exact: true,
    component: MODULES.Admin.Users,
    resource: Resources.UserListPage,
  },
  {
    path: '/clients',
    exact: true,
    component: MODULES.Admin.Clients,
    resource: Resources.ClientEntityListPage,
  },
  {
    path: '/roles',
    exact: true,
    component: MODULES.Admin.Roles,
    resource: Resources.RoleListPage,
  },
  {
    path: '/licensees',
    exact: true,
    component: MODULES.Admin.Licensees,
    resource: Resources.LicenseeListPage,
  },
  {
    path: '/resources',
    exact: true,
    component: MODULES.Admin.Resources,
    resource: Resources.ResourceEntityListPage,
  },
  {
    path: '/functional-spaces',
    exact: true,
    component: MODULES.Admin.FunctionalSpace,
    resource: Resources.FunctionalSpaceListPage,
  },
  {
    path: '/cost-category-schedule',
    exact: true,
    component: MODULES.Admin.CostCategories,
    resource: Resources.CostCategoryListPage,
  },
  {
    path: '/programmes',
    exact: true,
    component: MODULES.Admin.Programmes,
    resource: Resources.ProgrammeListPage,
  },
  {
    path: '/building-types',
    exact: true,
    component: MODULES.Admin.BuildingTypes,
    resource: Resources.BuildingTypeListPage,
  },
  {
    path: '/client-document-library',
    exact: true,
    component: MODULES.Admin.ClientDocuments,
    resource: Resources.ClientDocumentLibraryPage,
  },
  {
    path: '/permissions',
    exact: true,
    component: MODULES.Admin.Permission,
    resource: Resources.PermissionListPage,
  },
  {
    path: '/report',
    exact: true,
    component: MODULES.Report,
    resource: Resources.ReportPage,
  },
];

export { PUBLIC_PAGES, DASHBOARD_PAGES };
