import React, { ComponentProps, CSSProperties as StyleSheet } from 'react';
import { PageHeader } from 'antd';
import { Link } from 'react-router-dom';

import { WHITE, LIGHTEST_GREY } from '../constants/colors';

type Props = ComponentProps<typeof PageHeader> & {
  title: string;
  style?: StyleSheet;
};

export default function SubHeader({
  title,
  style,
  breadcrumb,
  ...rest
}: Props) {
  return (
    <PageHeader
      title={title}
      style={{ ...styles.root, ...style }}
      breadcrumb={{
        itemRender(route, params, routes, paths) {
          let { path } = route;
          if (path === '.' || path === '') {
            return <span>{route.breadcrumbName}</span>;
          } else {
            return (
              <Link style={{ color: '#24a9bb' }} to={paths.join('/')}>
                {route.breadcrumbName}
              </Link>
            );
          }
        },
        ...breadcrumb,
      }}
      {...rest}
    />
  );
}

let styles = {
  root: {
    borderBottom: `1px solid ${LIGHTEST_GREY}`,
    backgroundColor: WHITE,
    paddingTop: 16,
  },
};
