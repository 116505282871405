import React, { forwardRef, ComponentProps } from 'react';

import { Input as RawInput } from 'antd';

type Props = ComponentProps<typeof RawInput>;

function TextPassword(props: Props, ref: React.Ref<any>) {
  let { Password } = RawInput;
  return <Password ref={ref} {...props} />;
}

export default forwardRef(TextPassword);
