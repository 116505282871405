import React, { ReactNode, CSSProperties as StyleSheet } from 'react';
import styled, { CSSProperties } from 'styled-components';

let RawView = styled.div`
  align-items: stretch;
  border: 0 solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  outline: none;
`;

type Props = {
  style?: CSSProperties | StyleSheet;
  className?: String;
  id?: String;
  children?: ReactNode;
  onClick?: () => void;
};

export default React.forwardRef(({ children, ...otherProps }: Props, ref) => {
  return (
    // @ts-ignore
    <RawView ref={ref} {...otherProps}>
      {children}
    </RawView>
  );
});
