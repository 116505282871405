import React, { useState, createRef } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components';

import { HeaderIcon } from './Header';
import { View, Text, TextInput } from '../../core-ui';
import { getSelections } from '../../helpers';
import { Popover } from '../../components';

let HoveredView = styled(View)`
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover {
    background-color: rgba(40, 60, 70, 0.1);
  }
`;

export default function SearchProject({
  projects,
  projectsLoading,
  updateSelectedProject,
}) {
  let [isPopoverVisible, setPopoverVisibility] = useState<boolean>(false);
  let [searchInputValue, setSearchInputValue] = useState('');

  let searchInputDebounced = debounce(setSearchInputValue, 500);

  let {
    selectClientID,
    selectProgrammeID,
    selectRegionID,
    selectProjectID,
  } = getSelections();

  let searchInputRef = createRef<HTMLInputElement>();

  let filteredProjects = projects.filter(({ name }) =>
    name.toLowerCase().includes(searchInputValue.toLowerCase()),
  );

  return (
    <Popover
      placement="topRight"
      visible={isPopoverVisible}
      trigger="click"
      onVisibleChange={(visible) => {
        setPopoverVisibility(visible);
      }}
      content={
        <View style={{ width: 400 }}>
          <TextInput
            ref={searchInputRef}
            placeholder={projectsLoading ? 'Loading...' : 'Search project...'}
            disabled={projectsLoading}
            style={{
              padding: '4px 11px',
              borderWidth: 1,
              marginBottom: searchInputValue && filteredProjects ? 10 : 0,
            }}
            onChange={(e) => searchInputDebounced(e.target.value)}
          />
          {searchInputValue && (
            <View style={styles.searchResultBox}>
              {filteredProjects.map(
                ({ id, name, clientId, regionId, programmeId }) => (
                  <HoveredView
                    key={id}
                    onClick={() => {
                      updateSelectedProject({
                        variables: {
                          selection: JSON.stringify({
                            projectId: id,
                            regionId,
                            programmeId,
                            clientId,
                          }),
                        },
                      }).then(() => {
                        selectClientID(clientId);
                        selectProgrammeID(programmeId);
                        selectRegionID(regionId);
                        selectProjectID(id);
                        setPopoverVisibility(false);
                        setSearchInputValue('');
                      });
                    }}
                  >
                    <Text
                      size="xxSmall"
                      link
                      style={{ marginBottom: 5, marginTop: 5 }}
                    >
                      {name}
                    </Text>
                  </HoveredView>
                ),
              )}
            </View>
          )}
        </View>
      }
    >
      <View
        onClick={() => {
          setPopoverVisibility(!isPopoverVisible);
          if (!isPopoverVisible) {
            searchInputRef.current?.focus();
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <HeaderIcon
          name="search"
          color="#606060"
          size={20}
          style={{ marginRight: 30 }}
        />
      </View>
    </Popover>
  );
}

let styles = {
  searchResultBox: {
    maxHeight: 600,
    overflow: 'scroll',
  },
};
