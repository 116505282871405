import { ReactElement } from 'react';
import useResources from '../../hooks/useResources';

type Props = {
  resource?: {
    identifier: string;
    type: 'CONTROL' | 'PAGE';
  };
  children: ReactElement | null;
};

export default function AccessControl({
  resource,
  children,
}: Props): ReactElement | null {
  if (!resource) {
    return children;
  }

  let { allowedResources } = useResources();

  return isAllowed(resource.identifier, allowedResources) ? children : null;
}

function isAllowed(identifier, resourceList) {
  return Boolean(
    resourceList.find((resource) => resource.identifier === identifier),
  );
}
