import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import { View, Text, TouchableOpacity } from '../../core-ui';
import useTranslation from '../../helpers/useTranslation';

import {
  Menu as MenuType,
  MenuAction,
} from './utils/reducer';
import {WHITE } from '../../constants/colors';
import MenuItem from './MenuItem';

type MenuCollapseProps = MenuType & {
  isSidebarExpanded: boolean;
  dispatchMenus: React.Dispatch<MenuAction>;
  menuOpen: string[]
  level: number; 
};

export default function MenuCollapse({
  menuOpen,
  level,
  id,
  name,
  Icon,
  children,
  isSidebarExpanded,
  dispatchMenus,
}: MenuCollapseProps) {
  let t = useTranslation();
  let [open, setOpen] = useState(false);
  let [selected, setSelected] = useState('');

  let handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? id : '');
  };

  let { pathname, search } = useLocation();
  let checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.link === pathname || item.link === `${pathname}${search}`) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected('');
    if (children) {
      children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, id);
        }
        if (item.link === pathname || item.link === `${pathname}${search}`) {
          setSelected(id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, children]);

  let CollapsibleIcon = open ? UpOutlined : DownOutlined;

  let menuItems = children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <MenuCollapse
          {...menu}
          menuOpen={menuOpen}
          key={menu.id}
          level={level+1}
          dispatchMenus={dispatchMenus}
          isSidebarExpanded={isSidebarExpanded}
        />
      case 'item':
        return <MenuItem 
          {...menu}
          menuOpen={menuOpen}
          key={menu.id}
          level={level+1}
          dispatchMenus={dispatchMenus}
          isSidebarExpanded={isSidebarExpanded}

          />
      default:
        return (
          <Text>Menu Items Error</Text>
        )
    }
  })

  return (
    <View
      style={{
        ...styles.root,
        paddingTop: isSidebarExpanded && level > 1 ? 10 : 15,
        paddingBottom: isSidebarExpanded && level > 1 ? 10 : 15,
        borderBottomWidth: isSidebarExpanded ? 1 : 0,
        borderColor: isSidebarExpanded ? 'rgba(17, 24, 39, 0.1)' : '',
      }}
    >
      <TouchableOpacity
        data-testid={name}
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          // backgroundColor: selected === id && !isSidebarExpanded ? '#24A9BB' : '',
        }}
        onPress={handleClick}
      >
        {!isSidebarExpanded && (
          <Icon style={styles.parentIcon} />
        )}
        <View
          style={{
            transition: 'opacity 0.4s ease-in',
            opacity: isSidebarExpanded ? 1 : 0,
            flex: 1,
          }}
        >
          {isSidebarExpanded && (
            <View
              style={{
                ...styles.parentMenuTextWrapper,
                paddingLeft: `${level * 20}px`,
                alignItems: 'center',
                marginBottom: open ? 10 : 0,
              }}
            >
              <Text
                weight={selected === id ? '600' : '500'}
                size={'xxSmall'}
                style={{
                  alignSelf: 'center',
                  fontSize: level > 1 ? 12 : 14
                }}
                color={WHITE}
              >
                {t(name).toUpperCase()}
              </Text>
                <CollapsibleIcon
                  style={{
                    fontSize: 14,
                    color: 'rgba(17,24,39,1)',
                  }}
                />
            </View>
          )}
        </View>
      </TouchableOpacity>
      <View
        style={{
          transition: 'opacity 0.2s',
          opacity: open && isSidebarExpanded ? 1 : 0,
        }}
      >
        {isSidebarExpanded &&
          open &&
          menuItems}
      </View>
    </View>
  );
}


let styles = {
  root: {},
  parentMenuTextWrapper: {
    flex: 1,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    paddingRight: 30,
  },
  parentIcon: {
    color: '#fafafa',
    fontSize: 22,
    minWidth: 22,
    padding: 12,
    paddingRight: 15,
    paddingLeft: 23,
  },
  childIconWrapper: {
    flex: 1,
    flexDirection: 'row' as 'row',
  },
  childIcon: {
    marginRight: 10,
    marginTop: 1,
    fontSize: 18,
  },
};
