import React, { ReactNode, ComponentProps } from 'react';
import { Upload as RawUpload } from 'antd';

type Props = ComponentProps<typeof RawUpload> & {
  children?: ReactNode;
};

export default function Upload(props: Props) {
  return <RawUpload {...props} />;
}

Upload.Dragger = RawUpload.Dragger;
