import gql from 'graphql-tag';

const GET_CLIENTS = gql`
  query GetClients($limit: Limit, $orderBy: OrderBy) {
    clients(limit: $limit, orderBy: $orderBy) {
      totalCount
      hasNextPage
      limit {
        first
        offset
      }
      items {
        id
        name
        managingOffice
        sector
        contacts
        address
        city
        postcode
        state
        clientManagerId
        clientManager {
          id
          fName
          lName
        }
        people {
          id
          fName
          lName
        }
        primaryClientContactId
        primaryClientContact {
          fName
          lName
        }
      }
    }
  }
`;

const GET_PROGRAMMES = gql`
  query GetProgrammes($limit: Limit, $orderBy: OrderBy) {
    programmes(limit: $limit, orderBy: $orderBy) {
      totalCount
      hasNextPage
      limit {
        first
        offset
      }
      items {
        id
        name
        clientId
        client {
          id
          name
        }
        regions {
          id
          name
        }
        programmeManager {
          id
          fName
          lName
        }
        programmeManagerId
        reportCycle
        projectReportDate
        costCategories {
          id
          name
        }
        costTypes {
          id
          name
        }
        kpiBands1
        kpiBands2
        kpiBands3
        forecastFinalFrom
        forecastFinalTo
        forecastFinalReverseColor
        budgetVarianceFrom
        budgetVarianceTo
        budgetVarianceReverseColor
        paymentWorkFrom
        paymentWorkTo
        paymentWorkReverseColor
        milestoneFrom
        milestoneTo
        milestoneReverseColor
        amberWorkDoneMin
        amberWorkDoneMax
        amberContingencyDoneMin
        amberContingencyDoneMax
        redWorkDoneMin
        redWorkDoneMax
        redContingencyDoneMin
        redContingencyDoneMax
        createdDate
        modifiedDate
      }
    }
  }
`;

const GET_PROJECTS = gql`
  query GetProjects(
    $limit: Limit
    $orderBy: OrderBy
    $projectManagerFilter: PeopleFilter
  ) {
    projects(limit: $limit, orderBy: $orderBy) {
      totalCount
      hasNextPage
      limit {
        first
        offset
      }
      items {
        id
        code
        name
        description
        clientId
        regionId
        programmeId
        projectDirectorId
        statusProject
        client {
          id
          name
          address
          city
          state
          postcode
        }
        regionId
        region {
          id
          name
        }
        programmeId
        programme {
          id
          name
          programmeManagerId
          programmeManager {
            id
            fName
            lName
          }
        }
        projectDirector {
          id
          fName
          lName
        }
        projectManager: people(peopleFilter: $projectManagerFilter) {
          id
          fName
          lName
        }
      }
    }
  }
`;

const GET_REGIONS = gql`
  query GetRegions($limit: Limit, $orderBy: OrderBy) {
    regions(limit: $limit, orderBy: $orderBy) {
      totalCount
      hasNextPage
      limit {
        first
        offset
      }
      items {
        id
        clientId
        client {
          id
          name
        }
        name
        status
        createdDate
        createdBy {
          fName
          lName
        }
        modifiedDate
        modifiedBy {
          fName
          lName
        }
      }
    }
  }
`;

const DELETE_PROGRAMMES = gql`
  mutation DeleteProgrammes($programmeIDs: [String]!) {
    deleteProgrammesV2(ids: $programmeIDs)
  }
`;

const GET_CLIENT_FRAGMENT = gql`
  fragment client on ClientQL {
    id
    name
  }
`;

const GET_PROJECT_FRAGMENT = gql`
  fragment project on ProjectQl {
    id
    name
  }
`;

const GET_REGIONS_BY_CLIENT = gql`
  query GetRegionsByClient($clientID: String!, $limit: Limit) {
    regions(
      limit: $limit
      regionFilter: { clientFilter: { clientIds: [$clientID] } }
    ) {
      items {
        id
        name
      }
    }
  }
`;

const GET_PROJECT = gql`
  query GetProject(
    $projectID: String!
    $projectManagerFilter: PeopleFilter
    $projectReviewerFilter: PeopleFilter
    $projectOfficerFilter: PeopleFilter
    $independentVerifierFilter: PeopleFilter
    $builderFilter: PeopleFilter
    $qsAssuranceFilter: PeopleFilter
  ) {
    project(id: $projectID) {
      id
      code
      name
      description
      enableFunctionalSpace
      netLeaseArea
      builderName
      state
      buildingName
      location
      address
      gpsCoordinate
      feca
      uca
      tradeLettings
      isGrantFunding
      grantFundingStartDate
      reportCycle
      projectReportDate
      forecastFinalFrom
      forecastFinalTo
      forecastFinalReverseColor
      budgetVarianceFrom
      budgetVarianceTo
      budgetVarianceReverseColor
      paymentWorkFrom
      paymentWorkTo
      paymentWorkReverseColor
      milestoneFrom
      milestoneTo
      milestoneReverseColor
      amberWorkDoneMin
      amberWorkDoneMax
      amberContingencyDoneMin
      amberContingencyDoneMax
      redWorkDoneMin
      redWorkDoneMax
      redContingencyDoneMin
      redContingencyDoneMax
      costGroupIsEVMs
      initialBudget
      statusProject
      projectClosure
      certifications {
        id
        period
      }
      maxCertification {
        id
        no
        statusCertification
      }
      clientId
      client {
        id
        name
        address
        city
        state
        postcode
      }
      regionId
      region {
        id
        name
      }
      programmeId
      programme {
        id
        name
        programmeManager {
          id
          fName
          lName
        }
        costCategories {
          id
          costCode
          name
          costCategoryParent {
            id
            costCode
            name
          }
        }
      }
      projectDirector {
        id
        fName
        lName
      }
      milestones {
        id
        name
        initialTargetDate
        extensionDay
        modifiedDate
        revisedTargetDate
        actualDate
        variance
      }
      projectCostCategories {
        id
        initialBudget
        costCode
        initialBudget
        costCategoryId
        isEVM
        costCategory {
          id
          name
          costCode
        }
        parentCostCategoryId
      }
      projectFunctionalSpaces {
        id
        functionalSpaceId
        leaseArea
      }
      buildingType {
        id
        name
      }
      projectManager: people(peopleFilter: $projectManagerFilter) {
        fName
        lName
      }
      projectReviewer: people(peopleFilter: $projectReviewerFilter) {
        fName
        lName
      }
      projectOfficer: people(peopleFilter: $projectOfficerFilter) {
        fName
        lName
      }
      independentVerifier: people(peopleFilter: $independentVerifierFilter) {
        fName
        lName
      }
      builder: people(peopleFilter: $builderFilter) {
        fName
        lName
      }
      qsAssurance: people(peopleFilter: $qsAssuranceFilter) {
        fName
        lName
      }
    }
  }
`;

const GET_PROJECT_NAME = gql`
  query GetProject($projectID: String!) {
    project(id: $projectID) {
      name
    }
  }
`;

const GET_HISTORICAL_CERTIFICATIONS = gql`
  query GetHistoricalCertifications($projectId: String) {
    historicalCertificationsV2(projectId: $projectId) {
      id: certificationId
      certificationId
      certificationNo
      forecastFinalCost
      period
      status
      thisPayment
      workComplete
      projectId
      displayPeriod
      workProgressAssessment {
        recommendation
        summary
        issueNote
        particularIssueAreas {
          issueAreaId
        }
        projectProgress {
          percentageTimeValueVariance
        }
        wpaProjectDimensions {
          critical
          dimension {
            name
          }
        }
      }
      verification {
        projectOnBudgetTargetLevel
        projectOnBudgetRemark
        projectOnProgrammeTargetLevel
        projectOnProgrammeRemark
        qualityWorkmanshipTargetLevel
        qualityWorkmanshipRemark
        paymentCoincideTargetLevel
        paymentCoincideRemark
        createdBy {
          id
        }
      }
    }
  }
`;

const GET_COST_CATEGORIES = gql`
  query GetCostCategories($limit: Limit) {
    costCategories(limit: $limit) {
      items {
        id
        name
        client {
          id
          name
        }
        clientId
        costCode
        isDefault
        allowDelete
        isCostItem
        isContractCost
        parentId
        costCategoryParent {
          id
          name
        }
      }
    }
  }
`;

const GET_TRANSLATIONS = gql`
  query {
    translationTexts {
      code
      text
    }
  }
`;

const GET_PERSON = gql`
  query PersonDetail($personID: String!) {
    person(id: $personID) {
      id
      fName
      lName
      company
      email
      phoneNumber
      mobile
      address
      landingPage
      receivedEmail
      receivedNotification
      noticeDate
      createdDate
      createdBy {
        fName
        lName
      }
      modifiedDate
      modifiedBy {
        fName
        lName
      }
      saveSelection
      photoPath
      language {
        id
        name
        status
        code
      }
      organization {
        id
        name
        roles {
          ...RoleAndPermissionFields
        }
      }
      clients {
        id
        name
        roles {
          ...RoleAndPermissionFields
        }
      }
      projects {
        id
        name
        client {
          id
          name
        }
        roles {
          ...RoleAndPermissionFields
        }
      }
    }
  }

  fragment RoleAndPermissionFields on RoleQL {
    name
    status
    # normalizedName
    # type
    permissions {
      # id
      # parentId
      # name
      # type
      status
      resources {
        id
        identifier
        controlName
        # description
        # permissionId
        permissionValue
        status
        type
        menu {
          id
          description
        }
        menuId
      }
    }
  }
`;

const GET_CURRENT_LICENSEE = gql`
  query GetCurrentLicense {
    currentLicense {
      id
      name
      licenseType
      status
      url
      address
      contact
      phone
      logoPath
      currencyCode
    }
  }
`;

export {
  GET_CLIENTS,
  GET_PROGRAMMES,
  GET_PROJECTS,
  GET_PROJECT,
  GET_REGIONS,
  GET_CLIENT_FRAGMENT,
  GET_REGIONS_BY_CLIENT,
  DELETE_PROGRAMMES,
  GET_HISTORICAL_CERTIFICATIONS,
  GET_COST_CATEGORIES,
  GET_TRANSLATIONS,
  GET_PERSON,
  GET_CURRENT_LICENSEE,
  GET_PROJECT_FRAGMENT,
  GET_PROJECT_NAME,
};
