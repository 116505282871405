import ResourceType from './resourceType';

export const AsyncHomeRoute = {
  identifier: 'au.org.webcert.pages.AsyncHomeRoute',
  type: ResourceType.Page,
};

export const UserListPage = {
  identifier: 'au.org.webcert.pages.UserListPage',
  type: ResourceType.Page,
};

export const ClientEntityListPage = {
  identifier: 'au.org.webcert.pages.ClientEntityListPage',
  type: ResourceType.Page,
};

export const FunctionalSpaceListPage = {
  identifier: 'au.org.webcert.pages.FunctionalSpaceListPage',
  type: ResourceType.Page,
};

export const ResourceEntityListPage = {
  identifier: 'au.org.webcert.pages.ResourceEntityListPage',
  type: ResourceType.Page,
};

export const CreateResourceEntityPage = {
  identifier: 'au.org.webcert.pages.CreateResourceEntityPage',
  type: ResourceType.Page,
};

export const EditResourceEntityPage = {
  identifier: 'au.org.webcert.pages.EditResourceEntityPage',
  type: ResourceType.Page,
};

// Roles
export const RoleListPage = {
  identifier: 'au.org.webcert.pages.RoleListPage',
  type: ResourceType.Page,
};

// Regions
export const RegionListPage = {
  identifier: 'au.org.webcert.pages.RegionListPage',
  type: ResourceType.Page,
};

// Permissions
export const PermissionListPage = {
  identifier: 'au.org.webcert.pages.PermissionListPage',
  type: ResourceType.Page,
};

// Cost Types
export const CostCategoryListPage = {
  identifier: 'au.org.webcert.pages.CostCategoryListPage',
  type: ResourceType.Page,
};

// Programmes
export const ProgrammeListPage = {
  identifier: 'au.org.webcert.pages.ProgrammeListPage',
  type: ResourceType.Page,
};

// Projects
export const CreateProjectPage = {
  identifier: 'au.org.webcert.pages.CreateProjectPage',
  type: ResourceType.Page,
};

export const EditProjectPage = {
  identifier: 'au.org.webcert.pages.EditProjectPage',
  type: ResourceType.Page,
};

export const ProjectListPage = {
  identifier: 'au.org.webcert.pages.ProjectListPage',
  type: ResourceType.Page,
};

// Certifications
export const CreateSubTradeItemPage = {
  identifier: 'au.org.webcert.pages.CreateSubTradeItemPage',
  type: ResourceType.Page,
};

export const CreateTradeItemPage = {
  identifier: 'au.org.webcert.pages.CreateTradeItemPage',
  type: ResourceType.Page,
};

export const EditSubTradeItemPage = {
  identifier: 'au.org.webcert.pages.EditSubTradeItemPage',
  type: ResourceType.Page,
};

export const EditTradeItemPage = {
  identifier: 'au.org.webcert.pages.EditTradeItemPage',
  type: ResourceType.Page,
};

export const TradeCategoryPage = {
  identifier: 'au.org.webcert.pages.TradeCategoryPage',
  type: ResourceType.Page,
};

export const BudgetSummariesPage = {
  identifier: 'au.org.webcert.pages.BudgetSummariesPage',
  type: ResourceType.Page,
};

export const CertificationListPage = {
  identifier: 'au.org.webcert.pages.CertificationListPage',
  type: ResourceType.Page,
};

export const UploadCertificationPage = {
  identifier: 'au.org.webcert.pages.UploadCertificationPage',
  type: ResourceType.Page,
};

export const GrantFundingPage = {
  identifier: 'au.org.webcert.pages.GrantFundingPage',
  type: ResourceType.Page,
};

// Contacts
export const ContactListPage = {
  identifier: 'au.org.webcert.pages.ContactListPage',
  type: ResourceType.Page,
};

// Building Type
export const BuildingTypeListPage = {
  identifier: 'au.org.webcert.pages.BuildingTypeListPage',
  type: ResourceType.Page,
};

// Photos
export const PhotoListPage = {
  identifier: 'au.org.webcert.pages.PhotoListPage',
  type: ResourceType.Page,
};

// Related Documentation
export const RelatedDocumentListPage = {
  identifier: 'au.org.webcert.pages.RelatedDocumentListPage',
  type: ResourceType.Page,
};

// Milestones
export const MilestoneListPage = {
  identifier: 'au.org.webcert.pages.MilestoneListPage',
  type: ResourceType.Page,
};

// Project Detail
export const ProjectDetailPage = {
  identifier: 'au.org.webcert.pages.ProjectDetailPage',
  type: ResourceType.Page,
};

// Key Documents
export const KeyDocumentMappingPage = {
  identifier: 'au.org.webcert.pages.KeyDocumentMappingPage',
  type: ResourceType.Page,
};

export const ClientDocumentLibraryPage = {
  identifier: 'au.org.webcert.pages.ClientDocumentLibraryPage',
  type: ResourceType.Page,
};

// Cashflows
export const CashflowListPage = {
  identifier: 'au.org.webcert.pages.CashflowListPage',
  type: ResourceType.Page,
};

// Project User Role
export const ProjectUserRolePage = {
  identifier: 'au.org.webcert.pages.ProjectUserRolePage',
  type: ResourceType.Page,
};

// Reminder
export const ReminderListPage = {
  identifier: 'au.org.webcert.pages.ReminderListPage',
  type: ResourceType.Page,
};

// WPA
export const WorkProgressAssessmentPage = {
  identifier: 'au.org.webcert.pages.WorkProgressAssessmentPage',
  type: ResourceType.Page,
};

export const KPIPreviewPage = {
  identifier: 'au.org.webcert.pages.KPIPreviewPage',
  type: ResourceType.Page,
};

export const EditWPAHighlightPage = {
  identifier: 'au.org.webcert.pages.EditWPAHighlightPage',
  type: ResourceType.Page,
};

export const HighlightReportPage = {
  identifier: 'au.org.webcert.pages.HighlightReportPage',
  type: ResourceType.Page,
};

// Verification
export const VerificationPage = {
  identifier: 'au.org.webcert.pages.VerificationPage',
  type: ResourceType.Page,
};

// Report Page
export const ReportPage = {
  identifier: 'au.org.webcert.pages.ReportPage',
  type: ResourceType.Page,
};

export const ProjectClosurePage = {
  identifier: 'au.org.webcert.pages.ProjectClosurePage',
  type: ResourceType.Page,
};

// licensee
export const LicenseeListPage = {
  identifier: 'au.org.webcert.pages.LicenseesPage',
  type: ResourceType.Page,
};
