import React, { useCallback } from 'react';
import RawIdleTimer from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

const IDLE_TIMEOUT = 1000 * 60 * 20;

export default function IdleTimer() {
  let history = useHistory();

  let sessionString = localStorage?.getItem('session') ?? '{}';
  let session = JSON.parse(sessionString);
  let token = session.token;

  let onIdle = useCallback(() => {
    if (token) {
      localStorage.removeItem('session');
      history.push('/');
    }
  }, [token]);

  return <RawIdleTimer onIdle={onIdle} timeout={IDLE_TIMEOUT} />;
}
