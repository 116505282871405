import React from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import styled from 'styled-components';

import { View, Text, Icon, TouchableOpacity } from '../../core-ui';
import { Popover } from '../../components';
import { useTranslation } from '../../helpers';

let HoveredView = styled(TouchableOpacity)`
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover {
    background-color: rgba(40, 60, 70, 0.1);
  }
`;

const GREY = 'rgba(0, 0, 0, 0.65)';

const APP_VERSION = process.env.REACT_APP_VERSION;

export default function UserProfile({ history, person }) {
  let t = useTranslation();
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      title={
        <View
          onClick={() => history.push('/profile')}
          style={styles.currentUserWrapper}
        >
          {person?.photoPath ? (
            <img
              src={String(person?.photoPath)}
              alt={`changeImage`}
              style={{ width: 48, height: 48, borderRadius: '50%' }}
            />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} />
          )}
          <View style={styles.currentUserTextWrapper}>
            {person ? (
              <View>
                <Text weight="500" data-testid="icon-user" size="xxSmall" link>
                  {`${person?.fName} ${person?.lName}`}
                </Text>
                <Text weight="400" color="rgba(0, 0, 0, 0.5)" size="xxSmall">
                  {person?.email}
                </Text>
              </View>
            ) : (
              <Icon name="loading" />
            )}
          </View>
        </View>
      }
      content={
        <View style={{ justifyContent: 'space-around' }}>
          <HoveredView style={styles.menu}>
            <Link to="/" target="_blank">
              <Icon name="user" color={GREY} />
              <Text
                data-testid="icon-support"
                color={GREY}
                size="xxSmall"
                style={{ marginLeft: 10 }}
              >
                {t('SupportPortal')}
              </Text>
            </Link>
          </HoveredView>
          <HoveredView style={styles.menu}>
            <Link to="/" target="_blank">
              <Icon name="rocket" color={GREY} />
              <Text
                data-testid="icon-roadmap"
                color={GREY}
                size="xxSmall"
                style={{ marginLeft: 10 }}
              >
                {t('ProcostRoadMap')}
              </Text>
            </Link>
          </HoveredView>
          <HoveredView
            onClick={() => history.push('/settings')}
            style={styles.menu}
          >
            <Icon name="setting" color={GREY} />
            <Text
              data-testid="icon-setting"
              color={GREY}
              size="xxSmall"
              style={{ marginLeft: 10 }}
            >
              {t('Settings')}
            </Text>
          </HoveredView>
          <HoveredView
            style={styles.menu}
            onClick={() => {
              localStorage.removeItem('session');
              history.push('/login');
            }}
          >
            <Icon name="poweroff" color={GREY} />
            <Text
              data-testid="icon-logout"
              color={GREY}
              size="xxSmall"
              style={{ marginLeft: 10 }}
            >
              {t('Logout')}
            </Text>
          </HoveredView>
          <View>
            <Text
              size="xxSmall"
              color="#B0A9AD"
              style={{ marginTop: 10, fontSize: '12px' }}
            >
              {t('Version')}: {APP_VERSION?.slice(0, 10) ?? ''}
            </Text>
          </View>
        </View>
      }
    >
      {person?.photoPath ? (
        <TouchableOpacity style={{ cursor: 'pointer' }}>
          <img
            data-testid="icon-user-avatar"
            src={String(person?.photoPath)}
            alt={`ImageProfile`}
            style={styles.image}
          />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity>
          <Avatar
            size={32}
            icon={<UserOutlined />}
            style={{ marginLeft: 30 }}
          />
        </TouchableOpacity>
      )}
    </Popover>
  );
}

let styles = {
  currentUserWrapper: {
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 250,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  currentUserTextWrapper: {
    flex: 1,
    paddingLeft: 10,
    justifyContent: 'center',
  },
  menu: {
    flexDirection: 'row' as 'row',
    alignItems: 'center' as 'center',
  },
  image: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    marginLeft: 30,
  },
};
