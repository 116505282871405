// Taken from https://www.npmjs.com/package/react-gauge-test
import React, { Component } from 'react';

export default class ReactGauge extends Component {
  state = {
    radius: this.props.radius || 175,
    arcStrokeWidth: this.props.arcStrokeWidth || 3,
    tickOffset: this.props.tickOffset || 7,
    tickStrokeWidth: this.props.tickStrokeWidth || 2,
    tickLength: this.props.tickLength || 5,
    miniTickLength: this.props.miniTickLength || 1,
    miniTickStrokeWidth: this.props.miniTickStrokeWidth || 1,
    scaleDivisionNumber: this.props.scaleDivisionNumber || 10,
    tickLabelOffset: this.props.tickLabelOffset || 10,
    centralCircleRadius: this.props.centralCircleRadius || 10,
    isInnerNumbers: this.props.isInnerNumbers || false,
    arrowValue: this.props.arrowValue || 0,
    marks: this.props.marks || [0, 1, 2, 3, 4, 5, 6],
    ranges: this.props.ranges || [
      {
        start: 0,
        end: 4.5 / 6,
        color: '#666',
      },
      {
        start: 4.5 / 6,
        end: 5.5 / 6,
        color: '#ffa500',
      },
      {
        start: 5.5 / 6,
        end: 1,
        color: '#ff0000',
      },
    ],
    aperture: this.props.aperture || 80,
    contentWidth: this.props.contentWidth || 450,
    svgContainerWidth: this.props.svgContainerWidth || 450,
    svgContainerHeight: this.props.svgContainerHeight || 450,
  };

  handleChange(event) {
    this.setState({
      radius: event.target.radius,
      arcStrokeWidth: event.target.arcStrokeWidth,
      tickOffset: event.target.tickOffset,
      tickStrokeWidth: event.target.tickStrokeWidth,
      tickLength: event.target.tickLength,
      miniTickLength: event.target.miniTickLength,
      miniTickStrokeWidth: event.target.miniTickStrokeWidth,
      scaleDivisionNumber: event.target.scaleDivisionNumber,
      tickLabelOffset: event.target.tickLabelOffset,
      centralCircleRadius: event.target.centralCircleRadius,
      isInnerNumbers: event.target.isInnerNumbers,
      arrowValue: event.target.arrowValue,
      marks: event.target.marks,
      ranges: event.target.ranges,
      aperture: event.target.aperture,
      contentWidth: event.target.contentWidth,
      svgContainerWidth: event.target.svgContainerWidth,
      svgContainerHeight: event.target.svgContainerHeight,
    });
  }

  componentDidUpdate(prevProps) {
    // Ignore invalid changes ('from' value bigger than 'to')
    if (!this.props.isInvalid) {
      if (prevProps.marks !== this.props.marks) {
        this.setState({
          ranges: this.props.ranges,
          marks: this.props.marks,
        });
      }

      if (this.state.arrowValue !== this.props.arrowValue) {
        this.setState({
          arrowValue: this.props.arrowValue,
        });
      }
    }
  }

  render() {
    const gaugeRadius = this.state.radius;
    const gaugeArcStrokeWidth = this.state.arcStrokeWidth;
    const gaugeTickOffset = this.state.tickOffset;
    const gaugeTickStrokeWidth = this.state.tickStrokeWidth;
    const gaugeTickLength = this.state.tickLength;
    const gaugeMiniTickLength = this.state.miniTickLength;
    const gaugeMiniTickStrokeWidth = this.state.miniTickStrokeWidth;
    const gaugeScaleDivisionNumber = this.state.scaleDivisionNumber;
    const gaugeTickLabelOffset = this.state.tickLabelOffset;
    const gaugeCentralCircleRadius = this.state.centralCircleRadius;
    const gaugeInnerNumbers = this.state.isInnerNumbers;
    const gaugeArrowValue = this.state.arrowValue;
    const gaugeMarks = this.state.marks;
    const gaugeRanges = this.state.ranges;
    const gaugeAperture = this.state.aperture;
    const gaugeContentWidth = this.state.contentWidth;

    const width = gaugeContentWidth;
    const height = gaugeContentWidth;

    const center = {
      x: width / 2,
      y: height / 2,
    };

    const gaugeStart = -180 + gaugeAperture / 2;
    const gaugeEnd = 180 - gaugeAperture / 2;

    const gaugeLength = gaugeEnd - gaugeStart;

    const polarToCartesian = function(
      centerX,
      centerY,
      radius,
      angleInDegrees,
    ) {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    };

    const getArcs = function() {
      const drawArc = function(startAngle, endAngle, color, index) {
        const describeArc = function(x, y, radius, startAngle, endAngle) {
          const start = polarToCartesian(x, y, radius, endAngle);
          const end = polarToCartesian(x, y, radius, startAngle);

          const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

          const d = [
            'M',
            start.x,
            start.y,
            'A',
            radius,
            radius,
            0,
            arcSweep,
            0,
            end.x,
            end.y,
          ].join(' ');

          return d;
        };

        return React.createElement('path', {
          className: `gauge-arc-${index}`,
          key: index,
          fill: 'none',
          stroke: color,
          strokeWidth: gaugeArcStrokeWidth,
          d: describeArc(center.x, center.y, gaugeRadius, startAngle, endAngle),
        });
      };

      const arcs = [];

      gaugeRanges.forEach((range, i) => {
        arcs.push(
          drawArc(
            gaugeStart + range.start * gaugeLength,
            gaugeStart + range.end * gaugeLength,
            range.color,
            i,
          ),
        );
      });
      return arcs;
    };

    const getTicks = function() {
      const result = [];

      let indexValue = 0;

      gaugeMarks.forEach((mark, index, array) => {
        if (index !== 0) {
          const miniGaugeLength =
            gaugeLength / (array.length - 1) / gaugeScaleDivisionNumber;

          for (let i = 1; i < gaugeScaleDivisionNumber; i++) {
            const startPoint = polarToCartesian(
              center.x,
              center.y,
              gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * gaugeTickOffset,
              gaugeStart +
                (index * gaugeLength) / (array.length - 1) -
                miniGaugeLength * i,
            );
            const endPoint = polarToCartesian(
              center.x,
              center.y,
              gaugeRadius +
                (gaugeInnerNumbers ? -1 : 1) *
                  (gaugeTickOffset + gaugeMiniTickLength),
              gaugeStart +
                (index * gaugeLength) / (array.length - 1) -
                miniGaugeLength * i,
            );

            result.push(
              React.createElement('line', {
                className: 'gauge-mini-tick',
                key: indexValue++,
                stroke: '#b5b5b5',
                x1: startPoint.x,
                y1: startPoint.y,

                x2: endPoint.x,
                y2: endPoint.y,

                strokeWidth: gaugeMiniTickStrokeWidth,
              }),
            );
          }
        }
      });

      gaugeMarks.forEach((mark, index, array) => {
        const startPoint = polarToCartesian(
          center.x,
          center.y,
          gaugeRadius + (gaugeInnerNumbers ? -1 : 1) * gaugeTickOffset,
          gaugeStart + (index * gaugeLength) / (array.length - 1),
        );
        const endPoint = polarToCartesian(
          center.x,
          center.y,
          gaugeRadius +
            (gaugeInnerNumbers ? -1 : 1) * (gaugeTickOffset + gaugeTickLength),
          gaugeStart + (index * gaugeLength) / (array.length - 1),
        );

        result.push(
          React.createElement('line', {
            className: 'gauge-tick',
            key: indexValue++,
            stroke: '#b5b5b5',
            x1: startPoint.x,
            y1: startPoint.y,

            x2: endPoint.x,
            y2: endPoint.y,

            strokeWidth: gaugeTickStrokeWidth,
          }),
        );
      });
      return result;
    };

    const getLabels = function() {
      const result = [];

      let indexValue = 0;
      gaugeMarks.forEach((mark, index, array) => {
        const labelCoords = polarToCartesian(
          center.x,
          center.y,
          gaugeRadius +
            (gaugeInnerNumbers ? -1 : 1) *
              (gaugeTickOffset + gaugeTickLength + gaugeTickLabelOffset),
          gaugeStart + (index * gaugeLength) / (array.length - 1),
        );

        result.push(
          React.createElement(
            'text',
            {
              className: 'gauge-label',
              key: indexValue++,
              fill: '#666666',
              x: labelCoords.x,
              y: labelCoords.y,
              alignmentBaseline: 'middle',
              textAnchor: 'middle',
              strokeWidth: gaugeTickStrokeWidth,
            },
            mark,
          ),
        );
      });
      return result;
    };

    const getArrow = function() {
      const result = [];

      let indexValue = 0;

      const point1 = polarToCartesian(
        center.x,
        center.y,
        gaugeCentralCircleRadius / 2,
        gaugeStart + gaugeLength * gaugeArrowValue - 90,
      );
      const point2 = polarToCartesian(
        center.x,
        center.y,
        gaugeRadius +
          (gaugeInnerNumbers ? -1 : 1) *
            (gaugeTickOffset + gaugeTickLength / 2),
        gaugeStart + gaugeLength * gaugeArrowValue,
      );
      const point3 = polarToCartesian(
        center.x,
        center.y,
        gaugeCentralCircleRadius / 2,
        gaugeStart + gaugeLength * gaugeArrowValue + 90,
      );

      const describe = [
        'M',
        point1.x,
        point1.y,
        'L',
        point2.x,
        point2.y,
        'L',
        point3.x,
        point3.y,
        'Z',
      ].join(' ');

      result.push(
        React.createElement('path', {
          className: 'gauge-arrow',
          key: indexValue++,
          fill: '#1e98e4',
          d: describe,
        }),
      );

      result.push(
        React.createElement('circle', {
          className: 'gauge-arrow',
          key: indexValue++,
          fill: '#1e98e4',
          cx: center.x,
          cy: center.y,
          r: gaugeCentralCircleRadius,
        }),
      );
      return result;
    };

    return React.createElement(
      'svg',
      {
        className: 'gauge',
        width: this.state.svgContainerWidth,
        height: this.state.svgContainerHeight,
        shapeRendering: 'geometricPrecision',
      },
      getArcs(),
      getTicks(),
      getLabels(),
      getArrow(),
    );
  }
}
