import React, { Suspense, FC, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';

import { PUBLIC_PAGES, DASHBOARD_PAGES } from './routeList';
import { View } from '../core-ui';
import {
  NotAllowed,
  NotFound,
  FontSizeWrapper,
  LoadingScreen,
} from '../components';
import { Sidebar, Header } from '../layout';
import ClientProvider from '../providers/ClientProvider';
import store from '../store';
import { isResourceAllowed, setTranslations, validateToken } from '../helpers';
import useResources from '../hooks/useResources';
import { GET_TRANSLATIONS, GET_PROGRAMMES } from '../gql';

let AppRoutes: FC = () => {
  return (
    <ReduxProvider store={store}>
      <FontSizeWrapper>
        <Switch>
          {PUBLIC_PAGES.map(({ component, ...rest }, i) => (
            <Route
              key={i}
              {...rest}
              render={(props: any) => React.createElement(component, props)}
            />
          ))}
          <Route
            path="/"
            render={({ match: { url }, location }) => (
              <ClientProvider>
                <DashboardRoute url={url} location={location} />
              </ClientProvider>
            )}
          />
        </Switch>
      </FontSizeWrapper>
    </ReduxProvider>
  );
};

function DashboardRoute({ url, location }) {
  let i18n = useSelector((state) => state.i18n);
  let dispatch = useDispatch();

  let translationQuery = useQuery(GET_TRANSLATIONS);
  let programmesQuery = useQuery(GET_PROGRAMMES);

  let { allowedResources, isLoading } = useResources();

  useEffect(() => {
    let isTranslationsFetched = !i18n.chosenLanguage;
    isTranslationsFetched && setTranslations(translationQuery.data, dispatch);
  }, [translationQuery.data]);

  let isTokenValid = validateToken(programmesQuery.error);
  if (!isTokenValid) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    );
  }
  if (location.pathname === '/') {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: {
            from: location,
          },
        }}
      />
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.root}>
        <Sidebar />
        <View
          style={{
            flex: 1,
            minWidth: 980,
          }}
        >
          <Header />
          <View id="contentWrapper" style={styles.contentWrapper}>
            <Suspense fallback={<div />}>
              <Switch>
                {DASHBOARD_PAGES.map(
                  ({ path, resource, component, ...props }, i) => {
                    let shouldAllowPageAccess = resource
                      ? isResourceAllowed(resource.identifier, allowedResources)
                      : true;

                    let componentToRender = isLoading
                      ? LoadingScreen
                      : shouldAllowPageAccess
                      ? component
                      : NotAllowed;

                    return (
                      <Route
                        path={`${path}`}
                        key={i}
                        component={componentToRender}
                        {...props}
                      />
                    );
                  },
                )}
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </View>
        </View>
      </View>
    </View>
  );
}

let styles = {
  root: {
    flexDirection: 'row' as 'row',
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    overflowY: 'scroll' as 'scroll',
  },
};

export default AppRoutes;
