export const BLACK = '#000000';
export const BLUE = '#1E98E3';
export const GREY = '#6F6F6F';
export const LIGHT_GREY = '#CCCCCC';
export const LIGHTER_GREY = '#EBEBEB';
export const LIGHTEST_GREY = '#E8E8E8';
export const GREEN = '#24A9BB';
export const LIGHT_GREEN = '#39AB7F';
export const LIGHTER_GREEN = '#B7DBE0';
export const PALE_GREEN = '#455A64';
export const LIGHT_RED = '#EA4228';
export const LIGHT_WHITE = '#FAFAFA';
export const WHITE = '#FFFFFF';
export const RED = '#D96662';
export const YELLOW = '#F5CC18';
export const GRAYISH_RED = '#E6CCC9';
export const SCOTTISH_WHITE = '#FFF8EF';
