import React, { PureComponent, useState } from 'react';
import { FormikProps } from 'formik';

import {
  View,
  TouchableOpacity,
  Text,
  Icon,
  Button,
  Tooltip,
} from '../core-ui';
import { WHITE, PALE_GREEN } from '../constants/colors';
import { AccessControl, Modal } from '.';
import useTranslation from '../helpers/useTranslation';

type Resource = {
  type: 'CONTROL' | 'PAGE';
  identifier: string;
};

type FormHeaderProps = {
  title: Maybe<string>;
  defaultTitle?: string;
  isEditing?: boolean;
  isAdding?: boolean;
  saveButtonResource?: Resource;
  setEditing?: (isEditing: boolean) => void;
  onClose: () => void;
  withoutButtons?: boolean;
  formikProps?: FormikProps<any>;
  extra?: React.ReactElement;
  otherSource?: any;
  isAddToLocal?: boolean;
  customSubmit?: () => void;
  loading?: boolean;
  isProjectDetail?: boolean;
  positionComponentExtra?: 'left' | 'center' | 'right';
  modifiedSaveButtonName?: string;
};

export default class FormHeader extends PureComponent<FormHeaderProps> {
  render() {
    let {
      title,
      defaultTitle,
      isEditing,
      setEditing,
      isAdding,
      withoutButtons,
      onClose,
      formikProps,
      saveButtonResource,
      extra,
      otherSource,
      isAddToLocal,
      customSubmit,
      loading,
      isProjectDetail,
      positionComponentExtra,
      modifiedSaveButtonName,
    } = this.props;
    return (
      <View style={styles.sidebarHeader}>
        <Tooltip placement="bottom" title={title || defaultTitle}>
          <Text color={WHITE} bold style={styles.formHeaderText}>
            {title || defaultTitle}
          </Text>
        </Tooltip>
        <View style={styles.actionHeaderWrapper}>
          {!withoutButtons && (
            // @ts-ignore
            <SubmitButton
              isEditing={Boolean(isEditing)}
              setEditing={setEditing}
              isAdding={Boolean(isAdding)}
              onClose={onClose}
              saveButtonResource={saveButtonResource}
              {...formikProps}
              otherSource={otherSource}
              isAddToLocal={isAddToLocal}
              customSubmit={customSubmit}
              loading={loading}
              isProjectDetail={isProjectDetail}
              positionComponentExtra={positionComponentExtra}
              extra={extra}
              modifiedSaveButtonName={modifiedSaveButtonName}
            />
          )}
          {!positionComponentExtra && extra}
          <TouchableOpacity
            data-testid="button-close"
            style={styles.icon}
            onPress={onClose}
          >
            <Icon name="close" color={WHITE} size={16} />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

type SubmitProps = {
  isEditing: boolean;
  isAdding: boolean;
  setEditing?: (isEdit: boolean) => void;
  saveButtonResource?: Resource;
  onClose: (isButton?: boolean) => void;
  otherSource: any;
  isAddToLocal?: boolean;
  customSubmit?: () => void;
  loading?: boolean;
  isProjectDetail?: boolean;
  positionComponentExtra?: 'left' | 'center' | 'right';
  extra?: React.ReactElement;
  setSelectedRowKeys: (selectedKeys: string[] | number[]) => void;
  modifiedSaveButtonName?: string;
} & FormikProps<any>;

function SubmitButton({
  isEditing,
  isAdding,
  setEditing,
  dirty,
  handleReset,
  setSelectedRowKeys,
  isSubmitting,
  onClose,
  submitForm,
  otherSource,
  saveButtonResource,
  isAddToLocal,
  customSubmit,
  isProjectDetail,
  loading,
  positionComponentExtra,
  extra,
  modifiedSaveButtonName,
}: SubmitProps) {
  let [isCancelModalVisible, showCancelModal] = useState(false);
  let t = useTranslation();
  if (isAdding) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Modal
          visible={isCancelModalVisible}
          onCancel={() => showCancelModal(false)}
          onOk={() => showCancelModal(true)}
          style={{ borderRadius: 10 }}
          width={'25%'}
          footer={null}
          centered
          mask
        >
          <View style={{ height: 45 }}>
            <Text>{t('CancelCurrent')}</Text>
          </View>
          <View>
            <div>{t('LostChanges')}</div>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 30,
            }}
          >
            <Button
              data-testid="button-keepediting"
              shape="round"
              onPress={() => showCancelModal(false)}
            >
              {t('KeepEditing')}
            </Button>
            <Button
              data-testid="button-yescancel"
              shape="round"
              type="primary"
              style={{ marginLeft: 5 }}
              onPress={() => {
                setSelectedRowKeys && setSelectedRowKeys([]);
                handleReset();
                showCancelModal(false);
              }}
            >
              {t('YesCancel')}
            </Button>
          </View>
        </Modal>
        {positionComponentExtra === 'left' && extra}
        {dirty ? (
          <Button
            data-testid="button-cancel"
            onPress={() => showCancelModal(true)}
            style={positionComponentExtra === 'left' ? { marginLeft: 10 } : {}}
          >
            {t('Cancel')}
          </Button>
        ) : (
          <Button
            data-testid="button-cancel"
            onPress={() => {
              setSelectedRowKeys && setSelectedRowKeys([]);
              handleReset();
              setEditing && setEditing(false);
              showCancelModal(false);
              onClose();
            }}
            style={positionComponentExtra === 'left' ? { marginLeft: 10 } : {}}
          >
            {t('Cancel')}
          </Button>
        )}
        {positionComponentExtra === 'center' && extra}
        <AccessControl resource={saveButtonResource}>
          <Button
            data-testid="button-save"
            disabled={!dirty}
            type="primary"
            onPress={() => {
              setSelectedRowKeys && setSelectedRowKeys([]);
              submitForm();
            }}
            loading={isAddToLocal ? loading : isSubmitting}
            style={{ marginLeft: 10 }}
          >
            {t('Save')}
          </Button>
        </AccessControl>
        {positionComponentExtra === 'right' && extra}
      </View>
    );
  }
  return isEditing ? (
    <View style={{ flexDirection: 'row' }}>
      <Modal
        onCancel={() => showCancelModal(false)}
        visible={isCancelModalVisible}
        style={{ borderRadius: 10 }}
        width={'25%'}
        footer={null}
        centered
        mask
      >
        <View>
          <View style={{ height: 45 }}>
            <Text>{t('CancelCurrent')}</Text>
          </View>
          <View>
            <div>{t('LostChanges')}</div>
          </View>
          <View style={styles.cancelModalButton}>
            <Button
              data-testid="button-keepediting"
              shape="round"
              onPress={() => showCancelModal(false)}
            >
              {t('KeepEditing')}
            </Button>
            <Button
              data-testid="button-yescancel"
              shape="round"
              type="primary"
              style={{ marginLeft: 5 }}
              onPress={() => {
                setSelectedRowKeys && setSelectedRowKeys([]);
                handleReset();
                setEditing && setEditing(false);
                showCancelModal(false);
              }}
            >
              {t('YesCancel')}
            </Button>
          </View>
        </View>
      </Modal>
      <Button
        data-testid="button-cancel"
        onPress={() => {
          if (dirty || otherSource > 0) {
            showCancelModal(true);
          } else {
            setSelectedRowKeys && setSelectedRowKeys([]);
            handleReset();
            setEditing && setEditing(false);
            isProjectDetail && onClose(true);
          }
        }}
      >
        {t('Cancel')}
      </Button>
      <AccessControl resource={saveButtonResource}>
        <Button
          data-testid="button-save"
          type="primary"
          onPress={() => {
            if (isAddToLocal) {
              customSubmit && customSubmit();
            } else {
              submitForm();
            }
            setSelectedRowKeys && setSelectedRowKeys([]);
          }}
          disabled={isAddToLocal ? otherSource === 0 : !dirty}
          loading={isAddToLocal ? loading : isSubmitting}
          style={{ marginLeft: 10 }}
        >
          {modifiedSaveButtonName ? t('Unassign') : t('Save')}
        </Button>
      </AccessControl>
    </View>
  ) : (
    <TouchableOpacity
      data-testid="button-edit"
      style={styles.icon}
      onPress={() => setEditing && setEditing(true)}
    >
      <Icon name="edit" color={WHITE} size={16} />
    </TouchableOpacity>
  );
}

let styles = {
  sidebarHeader: {
    backgroundColor: PALE_GREEN,
    padding: 15,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  actionHeaderWrapper: {
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  formHeaderText: {
    textOverflow: 'ellipsis',
    width: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  cancelModalButton: {
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  icon: {
    marginLeft: 20,
  },
};
