import React from 'react';

import { View, TextInput, Button } from '../../core-ui';
import { LIGHT_WHITE } from '../../constants/colors';

import { FilterValue } from '../../types/table';

export type Props = {
  inputRef: React.Ref<any>;
  name: string;
  setFilteredValue?: (value: string | undefined) => void;
  selectedKeys: Array<any>;
  setSelectedKeys: (value: Array<any>) => void;
  confirm: () => void;
  clearFilters: () => void;
  filteredValue: FilterValue;
};

export default function FilterDropdown(props: Props) {
  let {
    name,
    selectedKeys,
    setSelectedKeys,
    confirm,
    clearFilters,
    inputRef,
    filteredValue,
    setFilteredValue,
  } = props;

  let value = (selectedKeys ? selectedKeys[0] : '') as string;

  return (
    <View style={styles.root}>
      <TextInput
        data-testid="text-search"
        ref={inputRef}
        placeholder={`Search...`}
        value={value}
        onChange={(e) =>
          setSelectedKeys &&
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          confirm && confirm();
          if (setFilteredValue) {
            setFilteredValue({
              ...filteredValue,
              [name]: value,
            });
          }
        }}
      />
      <View style={styles.buttonWrapper}>
        <Button
          data-testid="button-reset"
          onPress={() => {
            clearFilters && clearFilters();
            if (setFilteredValue) {
              setFilteredValue({
                ...filteredValue,
                [name]: undefined,
              });
            }
          }}
          style={styles.button}
        >
          Reset
        </Button>
        <Button
          data-testid="button-search"
          type="primary"
          onPress={() => {
            confirm && confirm();
            if (setFilteredValue) {
              setFilteredValue({
                ...filteredValue,
                [name]: value,
              });
            }
          }}
          style={{ ...styles.button, marginLeft: 5 }}
        >
          Search
        </Button>
      </View>
    </View>
  );
}

let styles = {
  root: {
    border: `1px solid ${LIGHT_WHITE}`,
    borderRadius: 5,
    padding: 20,
  },
  buttonWrapper: {
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
};
