import React from 'react';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { View } from '../core-ui';

type GlobalProps = { fontSize: number };

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${({ fontSize }: GlobalProps) => `${fontSize}px`};
  }

  @media screen and (min-width: 1200px) {
    html {
      font-size: ${({ fontSize }) => `${fontSize + 1}px`};
    }
  }

  @media screen and (min-width: 1500px) {
    html {
      font-size: ${({ fontSize }) => `${fontSize + 2}px`};
    }
  }
`;

export default function FontSizeWrapper({ children }) {
  let selectedFontSize = useSelector((state) => state.selectedFontSize);

  return (
    <View style={{ flex: 1 }}>
      <GlobalStyle fontSize={selectedFontSize} />
      {children}
    </View>
  );
}
