import { createStore, combineReducers } from 'redux';

type UserAction = {
  type: 'USER_LOGIN';
  payload: {
    email: string;
    token: string;
    userID: string;
    organization: string;
  };
};

function currentUserReducer(state = {}, action: UserAction) {
  switch (action.type) {
    case 'USER_LOGIN':
      return {
        email: action.payload.email,
        token: action.payload.token,
        userID: action.payload.userID,
        organization: action.payload.organization,
      };
    default:
      return state;
  }
}

type CountryCode = 'en' | 'id';

type TranslationAction =
  | {
      type: 'i18n/INIT_TRANSLATIONS';
      payload: {
        [countryCode: string]: {
          [key: string]: string;
        };
      };
    }
  | {
      type: 'i18n/SET_LANGUAGE';
      payload: {
        languageChosen: CountryCode;
      };
    };

function i18nReducer(
  state = {
    languageChosen: null,
    translations: {
      en: {},
      id: {},
    },
  },
  action: TranslationAction,
) {
  let { type, payload } = action;
  switch (type) {
    case 'i18n/INIT_TRANSLATIONS':
      return {
        ...state,
        translations: payload,
      };
    case 'i18n/SET_LANGUAGE': {
      return {
        ...state,
        languageChosen: payload.languageChosen,
      };
    }
    default:
      return state;
  }
}

type CurrencyAction = {
  type: 'currency/CHANGE_CURRENCY';
  payload: {
    selectedCurrency: 'IDR' | 'USD';
  };
};

function currencyReducer(state = 'USD', action: CurrencyAction) {
  switch (action.type) {
    case 'currency/CHANGE_CURRENCY':
      return action.payload.selectedCurrency;
    default:
      return state;
  }
}

function clientSettingsReducer(
  state = {
    color: {},
  },
  action,
) {
  switch (action.type) {
    case 'clientSettings/CHANGE_COLOR':
      return {
        ...state,
        color: {
          ...state.color,
          [action.payload.clientID]: action.payload.selectedColor,
        },
      };
    default:
      return state;
  }
}

function selectedFontSizeReducer(state = 13, action) {
  switch (action.type) {
    case 'fontSize/CHANGE_FONT_SIZE':
      return action.payload.selectedFontSize;
    default:
      return state;
  }
}

let rootReducer = combineReducers({
  currentUser: currentUserReducer,
  i18n: i18nReducer,
  currency: currencyReducer,
  clientSettings: clientSettingsReducer,
  selectedFontSize: selectedFontSizeReducer,
});

let preloadedState = {
  // @ts-ignore
  currentUser: JSON.parse(localStorage.getItem('session')) || undefined,
};

export default createStore(rootReducer, preloadedState);
