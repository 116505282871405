import gql from 'graphql-tag';

const GET_REMINDERS = gql`
  query GetReminders($limit: Limit, $isPastReminder: Boolean) {
    reminders(limit: $limit, isPastReminder: $isPastReminder) {
      totalCount
      hasNextPage
      limit {
        first
        offset
      }
      items {
        id
        actionType
        person {
          id
          fName
          lName
        }
        project {
          id
          name
        }
        subject
        createdDate
      }
    }
  }
`;

const UPDATE_REMINDERS = gql`
  mutation UpdateReminders($reminderIds: [String]!, $actionType: ActionType!) {
    updateActionTypeReminders(
      reminderIds: $reminderIds
      actionType: $actionType
    )
  }
`;

const DELETE_REMINDERS = gql`
  mutation DeleteReminders($reminderIds: [String]!) {
    deletePastReminders(ids: $reminderIds)
  }
`;

export { GET_REMINDERS, UPDATE_REMINDERS, DELETE_REMINDERS };
