import React, { ReactNode, ComponentProps } from 'react';

import { Tabs as RawTabs } from 'antd';

type Props = ComponentProps<typeof RawTabs> & {
  children: ReactNode;
};

export default function Tabs({
  animated = false,
  defaultActiveKey = '1',
  ...otherProps
}: Props) {
  return (
    <RawTabs
      animated={animated}
      defaultActiveKey={defaultActiveKey}
      {...otherProps}
    />
  );
}

Tabs.TabPane = RawTabs.TabPane;
