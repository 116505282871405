import React from 'react';
import { Menu } from 'antd';

import { LIGHT_WHITE } from '../../constants/colors';
import { View, Checkbox, Button } from '../../core-ui';

import { FilteredValue } from '../../types/table';

type Props = {
  name: string;
  selectedKeys: Array<any>;
  filteredValue: FilteredValue;
  setSelectedKeys: (value: Array<any>) => void;
  confirm: () => void;
  clearFilters: () => void;
  filters: Array<{ value: string; text: string }>;
  setFilteredValue: (value: FilteredValue) => void;
};

export default function CheckboxFilterDropdown(props: Props) {
  let {
    name,
    filteredValue,
    selectedKeys,
    setFilteredValue,
    setSelectedKeys,
    confirm,
    clearFilters,
    filters,
  } = props;

  let values = selectedKeys as string[];

  return (
    <View style={styles.root}>
      <Menu mode="vertical" selectedKeys={values}>
        {filters?.map(({ value, text }) => (
          <Menu.Item key={value} style={{ margin: 0 }}>
            <Checkbox
              checked={values.includes(value)}
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setSelectedKeys && setSelectedKeys([...values, value]);
                } else {
                  setSelectedKeys &&
                    setSelectedKeys(values.filter((item) => item !== value));
                }
              }}
            >
              {text}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
      <View style={styles.buttonWrapper}>
        <Button
          type="link"
          size="small"
          disabled={values.length === 0}
          onPress={() => {
            clearFilters && clearFilters();
            if (setFilteredValue) {
              setFilteredValue({
                ...filteredValue,
                [name]: undefined,
              });
            }
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onPress={() => {
            if (setFilteredValue) {
              setFilteredValue({ ...filteredValue, [name]: selectedKeys });
            }
            confirm && confirm();
          }}
        >
          OK
        </Button>
      </View>
    </View>
  );
}

let styles = {
  root: {
    border: `1px solid ${LIGHT_WHITE}`,
    borderRadius: 5,
  },
  buttonWrapper: {
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    padding: '7px 8px 7px 3px',
    borderTop: '1px solid #e8e8e8',
  },
};
