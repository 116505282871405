import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { View } from '../core-ui';

const antIcon = (
  <LoadingOutlined style={{ color: 'rgba(0 , 0 , 0, 0.5)', fontSize: 20 }} spin />
);

export default function LoadingScreen() {
  return (
    <View style={{ marginTop: 20 }}>
      <Spin indicator={antIcon} />
    </View>
  );
}
