import React, { forwardRef, ComponentProps } from 'react';

import { Input } from 'antd';

type Props = ComponentProps<typeof Input>;

function Search(props: Props, ref: React.Ref<any>) {
  return <Input.Search ref={ref} {...props} />;
}

export default forwardRef(Search);
