import * as Resources from '../../../components/AccessControl/utils/pageResources';
import {
  HomeOutlined,
  FundProjectionScreenOutlined,
  SolutionOutlined,
  ProjectOutlined,
  LineChartOutlined,
  CalendarOutlined,
  FileTextOutlined,
  ContactsOutlined,
  HddOutlined,
  CameraOutlined,
  AlertOutlined,
  IssuesCloseOutlined,
  FundViewOutlined,
  HistoryOutlined,
  CloudUploadOutlined,
  AuditOutlined,
  DeliveredProcedureOutlined,
  FileDoneOutlined,
  UserOutlined,
  ClusterOutlined,
  BlockOutlined,
  LockOutlined,
  TeamOutlined,
  ApartmentOutlined,
  ShopOutlined,
  SmileOutlined,
  ScheduleOutlined,
  AppstoreAddOutlined,
  ReadOutlined,
  GlobalOutlined,
  GoldOutlined,
  BarChartOutlined
} from '@ant-design/icons';

function getInitialState(t) {
  return {
    isOpen:[], // for active default menu
    defaultId: 'dashboard',
    isSidebarExpanded: false,
    menus: [
      {
        id: 'dashboard',
        name: 'Dashboard',
        Icon: HomeOutlined,
        link: '/dashboard',
        type: 'item',
        resource: Resources.ReportPage
      },
      {
        id: 'project',
        name: t('Project'),
        type: 'collapse',
        Icon: FundProjectionScreenOutlined,
        children: [
          {
            id: 'project-details',
            name: t('ProjectDetails'),
            type: 'item',
            Icon: ProjectOutlined,
            link: '/project-details',
            resource: Resources.ProjectDetailPage,
          },
          {
            id: 'cashflows',
            name: t('Cashflows'),
            type: 'item',
            Icon: LineChartOutlined,
            link: '/cashflows',
            resource: Resources.CashflowListPage,
          },
          {
            id: 'milestones',
            name: t('Milestones'),
            type: 'item',
            Icon: CalendarOutlined,
            link: '/milestones',
            resource: Resources.MilestoneListPage,
          },
          {
            id: 'project-document-registry',
            name: t('ProjectDocumentRegistry'),
            type: 'item',
            Icon: FileTextOutlined,
            link: '/project-document-registry',
            resource: Resources.KeyDocumentMappingPage,
          },
          {
            id: 'contacts',
            name: t('Contacts'),
            type: 'item',
            Icon: ContactsOutlined,
            link: '/contacts',
            resource: Resources.ContactListPage,
          },
          {
            id: 'document-management',
            type: 'item',
            name: t('DocumentManagement'),
            Icon: HddOutlined,
            link: '/document-management',
            resource: Resources.RelatedDocumentListPage,
          },
          {
            id: 'photos',
            type: 'item',
            name: t('Photos'),
            Icon: CameraOutlined,
            link: '/photos',
            resource: Resources.PhotoListPage,
          },
          {
            id: 'reminders',
            type: 'item',
            name: t('Reminders'),
            Icon: AlertOutlined,
            link: '/reminders',
            resource: Resources.ReminderListPage,
          },
          {
            id: 'project-closure',
            type: 'item',
            name: t('ProjectClosure'),
            Icon: IssuesCloseOutlined,
            link: '/project-closure',
            resource: Resources.ProjectClosurePage,
          },
        ],
      },
      {
        id: 'certification',
        type: 'collapse',
        name: t('Certifications'),
        Icon: SolutionOutlined,
        children: [
          {
            id: 'budget-summaries',
            type: 'item',
            name: t('BudgetSummaries'),
            Icon: FundViewOutlined,
            link: '/budget-summaries',
            resource: Resources.BudgetSummariesPage,
          },
          {
            id: 'historical-ccertification',
            type: 'item',
            name: t('HistoricalCertifications'),
            Icon: HistoryOutlined,
            link: '/historical-certifications',
            resource: Resources.CertificationListPage,
          },
          {
            id: 'upload-certification',
            type: 'item',
            name: t('UploadCertifications'),
            Icon: CloudUploadOutlined,
            link: '/upload-certifications',
            resource: Resources.UploadCertificationPage,
          },
        ],
      },
      {
        id: 'assessments',
        type: 'collapse',
        name: t('Assessments'),
        Icon: AuditOutlined,
        children: [
          {
            id: 'work-progress-assessment',
            type: 'item',
            name: t('WorkProgressAssessment'),
            Icon: DeliveredProcedureOutlined,
            link: '/work-progress-assessment',
            resource: Resources.WorkProgressAssessmentPage,
          },
          {
            id: 'verification',
            type: 'item',
            name: t('Verification'),
            Icon: FileDoneOutlined,
            link: '/verification',
            resource: Resources.VerificationPage,
          },
        ],
      },
      {
        id: 'project-report',
        type: 'collapse',
        name: t('Project Reports'),
        Icon: BarChartOutlined,
        children: [
          {
            id: 'financial-reports',
            type: 'collapse',
            name: t('Financial Reports'),
            Icon: BarChartOutlined,
            children: [
              {
                id: 'report-cashflow',
                type: 'item',
                name: t('Cashflows'),
                Icon: BarChartOutlined,
                link: '/report?folder=FinancialReports&name=Cashflow_Report',
                resource: Resources.ReportPage
              },
              {
                id: 'report-evm-analysis',
                type: 'item',
                name: t('EVM Analysis'),
                Icon: BarChartOutlined,
                link: '/report?folder=FinancialReports&name=EVMAnalysisReport',
                resource: Resources.ReportPage
              },
              {
                id: 'report-variations-report',
                type: 'item',
                name: t('Variations Report'),
                Icon: BarChartOutlined,
                link: '/report?folder=FinancialReports&name=View_Variations',
                resource: Resources.ReportPage
              },
              {
                id: 'report-gfa-measures',
                type: 'item',
                name: t('GFA Measures'),
                Icon: BarChartOutlined,
                link: '/report?folder=FinancialReports&name=GFA_Measures',
                resource: Resources.ReportPage
              },
              {
                id: 'report-purchase-order',
                type: 'item',
                name: t('Purchase Order'),
                Icon: BarChartOutlined,
                link: '/report?folder=FinancialReports&name=PurchaseOrder',
                resource: Resources.ReportPage
              },
            ]
          },
          {
            id: 'management-reports',
            type: 'collapse',
            name: t('Management Reports'),
            Icon: BarChartOutlined,
            children: [
              {
                id: 'report-kpi',
                type: 'item',
                name: t('KPI Report'),
                Icon: BarChartOutlined,
                link: '/report?folder=ManagementReports&name=KPI_Report',
                resource: Resources.ReportPage
              },
              {
                id: 'report-wpa-highlights',
                type: 'item',
                name: t('WPA Highlights Report'),
                Icon: BarChartOutlined,
                link: '/report?folder=ManagementReports&name=WPAHighlight',
                resource: Resources.ReportPage
              },
              {
                id: 'report-incomplete-project-document-registry',
                type: 'item',
                name: t('Incomplete Project Document Registry'),
                Icon: BarChartOutlined,
                link: '/report?folder=ManagementReports&name=Project_Document_Registry_Report',
                resource: Resources.ReportPage
              },
              {
                id: 'report-certification-period',
                type: 'item',
                name: t('Certification Period by Status'),
                Icon: BarChartOutlined,
                link: '/report?folder=ConsultantReports&name=ReportingPeriodByStatus',
                resource: Resources.ReportPage
              },
            ]
          },
          {
            id: 'system-reports',
            type: 'collapse',
            name: t('System Reports'),
            Icon: BarChartOutlined,
            children: [
              {
                id: 'report-access-permission',
                type: 'collapse',
                name: t('Access and Permission'),
                Icon: BarChartOutlined,
                children: [
                  {
                    id: 'report-all-role-permission',
                    type: 'item',
                    name: t('All Role Permission'),
                    Icon: BarChartOutlined,
                    link: '/report?folder=SystemReports&name=All_Access_Role_Permission',
                    resource: Resources.ReportPage
                  },
                  {
                    id: 'report-project-access',
                    type: 'item',
                    name: t('Project Access'),
                    Icon: BarChartOutlined,
                    link: '/report?folder=SystemReports&name=Access_and_Permission',
                    resource: Resources.ReportPage
                  },
                  {
                    id: 'report-user-project-role',
                    type: 'item',
                    name: t('User Project Role'),
                    Icon: BarChartOutlined,
                    link: '/report?folder=SystemReports&name=User_Role_Permission',
                    resource: Resources.ReportPage
                  },
                  {
                    id: 'report-role-permission',
                    type: 'item',
                    name: t('Role Permission'),
                    Icon: BarChartOutlined,
                    link: '/report?folder=SystemReports&name=RolePermission',
                    resource: Resources.ReportPage
                  },
                  {
                    id: 'report-user-permission',
                    type: 'item',
                    name: t('User Permission'),
                    Icon: BarChartOutlined,
                    link: '/report?folder=SystemReports&name=UserPermission',
                    resource: Resources.ReportPage
                  }
                ]
              },
              {
                id: 'report-project-summary',
                type: 'item',
                name: t('Project Summary'),
                Icon: BarChartOutlined,
                link: '/report?folder=SystemReports&name=Project_Summary',
                resource: Resources.ReportPage
              },
              {
                id: 'report-project-details',
                type: 'item',
                name: t('Project Details'),
                Icon: BarChartOutlined,
                link: '/report?folder=SystemReports&name=ProjectDetails',
                resource: Resources.ReportPage
              },
              {
                id: 'report-user-engagement',
                type: 'item',
                name: t('User Engagement'),
                Icon: BarChartOutlined,
                link: '/report?folder=Dashboard&name=User_Engagement',
                resource: Resources.ReportPage
              }
            ]
          },
          {
            id: 'funding-source-reports',
            type: 'collapse',
            name: t('Funding Source Reports'),
            Icon: BarChartOutlined,
            children: [
              {
                id: 'report-funding-source-summary',
                type: 'item',
                name: t('Summary Report'),
                Icon: BarChartOutlined,
                link: '/report?folder=FundingSource&name=Funding_Source_Summary',
                resource: Resources.ReportPage
              },
              {
                id: 'report-funding-source-distribution',
                type: 'item',
                name: t('Funding Source Distribution'),
                Icon: BarChartOutlined,
                link: '/report?folder=FundingSource&name=Funding_Source_Distribution',
                resource: Resources.ReportPage
              },
              {
                id: 'report-project-funding',
                type: 'item',
                name: t('Project Funding Report'),
                Icon: BarChartOutlined,
                link: '/report?folder=FundingSource&name=Project_Funding_Report',
                resource: Resources.ReportPage
              },
              {
                id: 'report-funding-detailde-breakdown',
                type: 'item',
                name: t('Funding - Detailed Breakdown'),
                Icon: BarChartOutlined,
                link: '/report?folder=FundingSource&name=Funding_Detailed_Breakdown',
                resource: Resources.ReportPage
              },
            ]
          },
        ],
      },
      {
        id: 'admin',
        type: 'collapse',
        name: t('Admin'),
        Icon: UserOutlined,
        children: [
          {
            id: 'licensees',
            type: 'item',
            name: t('Licensees'),
            Icon: BlockOutlined,
            link: '/licensees',
            resource: Resources.LicenseeListPage,
          },
          {
            id: 'users',
            type: 'item',
            name: t('Users'),
            Icon: TeamOutlined,
            link: '/users',
            resource: Resources.UserListPage,
          },
          {
            id: 'roles',
            type: 'item',
            name: t('Roles'),
            Icon: ApartmentOutlined,
            link: '/roles',
            resource: Resources.RoleListPage,
          },
          {
            id: 'permissions',
            type: 'item',
            name: t('Permissions'),
            Icon: LockOutlined,
            link: '/permissions',
            resource: Resources.PermissionListPage,
          },
          {
            id: 'resources',
            type: 'item',
            name: t('Resources'),
            Icon: ClusterOutlined,
            link: '/resources',
            resource: Resources.ResourceEntityListPage,
          },
          {
            id: 'building-types',
            type: 'item',
            name: t('BuildingTypes'),
            Icon: ShopOutlined,
            link: '/building-types',
            resource: Resources.BuildingTypeListPage,
          },
          {
            id: 'clients',
            type: 'item',
            name: t('Clients'),
            Icon: SmileOutlined,
            link: '/clients',
            resource: Resources.ClientEntityListPage,
          },
          {
            id: 'cost-category-schedule',
            type: 'item',
            name: t('CostCategorySchedule'),
            Icon: ScheduleOutlined,
            link: '/cost-category-schedule',
            resource: Resources.CostCategoryListPage,
          },
          {
            id: 'functional-spaces',
            type: 'item',
            name: t('FunctionalSpaces'),
            Icon: AppstoreAddOutlined,
            link: '/functional-spaces',
            resource: Resources.FunctionalSpaceListPage,
          },
          {
            id: 'client-document-library',
            type: 'item',
            name: t('ClientDocumentLibrary'),
            Icon: ReadOutlined,
            link: '/client-document-library',
            resource: Resources.ClientDocumentLibraryPage,
          },
          {
            id: 'regions',
            type: 'item',
            name: t('Regions'),
            Icon: GlobalOutlined,
            link: '/regions',
            resource: Resources.RegionListPage,
          },
          {
            id: 'programmes',
            type: 'item',
            name: t('Programmes'),
            Icon: GoldOutlined,
            link: '/programmes',
            resource: Resources.ProgrammeListPage,
          },
          {
            id: 'projects',
            type: 'item',
            name: t('Projects'),
            Icon: FundProjectionScreenOutlined,
            link: '/projects',
            resource: Resources.ProjectListPage,
          },
        ],
      },
    ],
  };
}

export default getInitialState;
