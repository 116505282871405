export default function compareString(a: Maybe<string>, b: Maybe<string>) {
  if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  } else if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  return 0;
}
