import React from 'react';

import { Result } from '../';

export default function NotAllowed() {
  return (
    <Result
      status="404"
      title="Permission required!"
      subTitle="You are not allowed to view this content."
    />
  );
}
