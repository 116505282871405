import React from 'react';
import { Badge } from 'antd';
import { useQuery } from '@apollo/client';

import { HeaderIcon } from './Header';
import { View, Tooltip } from '../../core-ui';

import { GET_REMINDERS } from '../../screens/Dashboard/Project/Reminders/utils/gql';

export default function Reminders({ history }) {
  let newRemindersQuery = useQuery(GET_REMINDERS, {
    variables: {
      limit: {
        first: 1000,
        offset: 0,
      },
      isPastReminder: false,
    },
  });

  let newReminders = newRemindersQuery.data?.reminders.items || [];

  return (
    <View
      onClick={() => history.push('/reminders')}
      style={{ cursor: 'pointer' }}
    >
      <Tooltip title="Reminders" placement="bottom">
        <Badge
          dot={newReminders.length > 0}
          style={{ backgroundColor: '#D96565' }}
        >
          <HeaderIcon name="bell" color="#606060" size={20} />
        </Badge>
      </Tooltip>
    </View>
  );
}
