export default function extractSubdomain(url) {
  let regex = /(?:https?:\/\/)?(?:www.)?([^.]+)\.(?:[^.]+\.)+com/;
  let result = regex.exec(url);
  if (result && result.length) {
    // ex: subdomain.google.com or www.google.com
    let subdomain = result[1];
    return subdomain !== 'www' ? subdomain : null;
  }
  return null;
}
