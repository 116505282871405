import React, { createRef, ComponentProps } from 'react';
import { Button as RawButton } from 'antd';

import useColorSettings from '../hooks/useColorSettings';

type Props = ComponentProps<typeof RawButton> & {
  asSubmit?: boolean;
  onPress?: () => void;
};

export default function Button({
  type,
  asSubmit = false,
  onPress,
  style,
  disabled,
  danger,
  ...otherProps
}: Props) {
  let selectedColor = useColorSettings();
  let buttonRef = createRef<HTMLButtonElement>();
  return (
    <>
      {asSubmit && (
        <button ref={buttonRef} type="submit" style={{ display: 'none' }} />
      )}
      <RawButton
        type={type}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          asSubmit && buttonRef.current?.click();
          onPress && onPress();
        }}
        disabled={disabled}
        style={Object.assign(
          {},
          style,
          type === 'primary' &&
            !disabled &&
            !danger && {
              backgroundColor: selectedColor,
              borderColor: selectedColor,
            },
        )}
        danger={danger}
        {...otherProps}
      />
    </>
  );
}
