import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { View, Text, TouchableOpacity } from '../../core-ui';
import useTranslation from '../../helpers/useTranslation';

import {
  Menu as MenuType,
  MenuAction,
} from './utils/reducer';
import { BLACK, GREEN, PALE_GREEN } from '../../constants/colors';

let HoveredMenu = styled(View)`
  flex-direction: row;
  background-color: ${({ isSelected }: { isSelected: boolean }) => (isSelected ? GREEN : PALE_GREEN)};
  margin-left: 10px;
  margin-right: 13px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  flex: 1;
  border-radius: 6px;
  color: #fafafa;

  &:hover {
    background-color: #e4e4e4;
    color: ${BLACK};
  }
`;

type ChildMenuProps = MenuType & {
  dispatchMenus: React.Dispatch<MenuAction>;
  level: number;
  menuOpen: string[] 
  isSidebarExpanded: boolean;
};

export default function MenuItem({
  level,
  menuOpen,
  id,
  name,
  link,
  Icon,
  dispatchMenus,
  isSidebarExpanded
}: ChildMenuProps) {
  let t = useTranslation();
  let history = useHistory();
  let { pathname, search } = useLocation();

  useEffect(() => {
    if (link === pathname || link === `${pathname}${search}`) {
      dispatchMenus({ type: "MENU_OPEN", menuId: id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  let isSelected = menuOpen.findIndex((menuId) => id === menuId) > -1

  return (
    <TouchableOpacity
      data-testid={name}
      onPress={() => {
        dispatchMenus({ type: 'MENU_OPEN', menuId:id });
        history.push(`${link}`);
      }}
      style={{ justifyContent: 'space-between' }}
    >
      {!isSidebarExpanded ? (
          <Icon style={styles.parentIcon} />
        ) :(
        <View style={styles.childIconWrapper}>
          <HoveredMenu isSelected={isSelected} style={{paddingLeft: `${level * 20}px`}}>
            <Icon style={styles.childIcon} />
            <Text weight={isSelected ? '500' : '300'} size="xxSmall">
              {t(name)}
            </Text>
          </HoveredMenu>
        </View>
      )}
    </TouchableOpacity>
  );
}

let styles = {
  root: {},
  parentMenuTextWrapper: {
    flex: 1,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    paddingRight: 30,
  },
  parentIcon: {
    color: '#fafafa',
    fontSize: 22,
    minWidth: 22,
    padding: 12,
    paddingRight: 15,
    paddingLeft: 23,
  },
  childIconWrapper: {
    flex: 1,
    flexDirection: 'row' as 'row',
  },
  childIcon: {
    marginRight: 10,
    marginTop: 1,
    fontSize: 18,
  },
};
