import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import base64 from 'base-64';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

import Carousel1 from '../../assets/budget-carousel.png';
import Carousel2 from '../../assets/historical-carousel.png';
import Carousel3 from '../../assets/dashboard-carousel.png';
import Carousel4 from '../../assets/wpa-carousel.png';

import Slider from './components/Slider';
import {
  View,
  Text,
  Button,
  TextInput,
  LoadingIndicator,
  TouchableOpacity,
  TextPassword,
  Checkbox,
} from '../../core-ui';
import { GREEN, WHITE } from '../../constants/colors';
import { getOrganization } from '../../helpers';
import { LOGIN, RESET_PASSWORD } from './gql';

const CAROUSEL_TEXTS = [
  {
    title: 'Budget Management System',
    content:
      'We ensure you to keep pace with the ever-increasing requirement of projects to manage budget.',
  },
  {
    title: 'Robust Technology',
    content:
      'Built using leading edge technology to provide efficiency in use without compromise to work processes or security',
  },
  {
    title: 'Ease of Use',
    content:
      'Easy to use budgeting software, specifically designed to increase work productivity without  compromise on security and work process.',
  },
  {
    title: 'Project KPI',
    content:
      'Key Project Budget KPI graphical information delivered on a central Project Dashboard. Secure role-based access ensures information presented is appropriate for Project role.',
  },
];

const CAROUSEL_IMAGES = [Carousel1, Carousel2, Carousel3, Carousel4];

function Login() {
  return (
    <View style={styles.root}>
      <Carousel />
      <LoginForm />
    </View>
  );
}

function Carousel() {
  return (
    <View style={styles.leftWrapper}>
      <View style={{ maxWidth: 550 }}>
        <Slider>
          {CAROUSEL_TEXTS.map(({ title, content }, i) => (
            <View key={i}>
              <img src={CAROUSEL_IMAGES[i]} alt="" style={{ width: '100%' }} />
              <View style={{ paddingTop: 35, paddingBottom: 50 }}>
                <View>
                  <Text
                    size="xxLarge"
                    color={WHITE}
                    style={styles.carouselTitle}
                  >
                    {title}
                  </Text>
                  <Text
                    size="small"
                    color={WHITE}
                    style={styles.carouselContent}
                  >
                    {content}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </Slider>
      </View>
    </View>
  );
}

function LoginForm() {
  let [shouldShowResetPassword, showResetPassword] = useState(false);
  let history = useHistory();
  let dispatch = useDispatch();
  let [shouldShowError, showError] = useState<null | string>(null);
  let [shouldRememberUser, setRememberMe] = useState(
    Boolean(localStorage.getItem('rememberMe')),
  );

  useEffect(() => {
    let sessionString = localStorage?.getItem('session') ?? '{}';
    let session = JSON.parse(sessionString);

    //TODO: check token validity and expiration
    let isTokenValid = session?.token;
    if (isTokenValid) {
      history.push('/dashboard');
    }
  }, []);

  let [getToken, { loading }] = useLazyQuery(LOGIN, {
    onCompleted: (data) => {
      if (!data || !data.login) {
        return showError('Network failure.');
      }

      let { email, token } = data?.login;
      let userDataEncoded = token.split('.')[1];
      let userDataDecoded = base64.decode(userDataEncoded);
      let { userId, organization } = JSON.parse(userDataDecoded);
      dispatch({
        type: 'USER_LOGIN',
        payload: {
          token,
          email,
          userID: userId,
          organization,
        },
      });
      localStorage.setItem(
        'session',
        JSON.stringify({
          token,
          userID: userId,
          organization,
          email,
        }),
      );
      history.push('/dashboard');
      showError(null);
    },
    onError() {
      showError('Incorrect email / password.');
    },
  });

  let [resetPassword] = useLazyQuery(RESET_PASSWORD, {
    onCompleted({ resetPassword }) {
      if (Boolean(resetPassword)) {
        notification.success({
          message: `Reset Password Successful`,
          description: 'Please check your email.',
        });
      } else {
        notification.error({
          message: `Reset Password Fail`,
          description: `Email not found.`,
          duration: 4,
        });
      }
    },
  });

  let formik = useFormik({
    initialValues: {
      email: localStorage.getItem('rememberMe') || '',
      password: '',
    },
    onSubmit: ({ email, password }) => {
      let organization = getOrganization();
      shouldShowResetPassword
        ? resetPassword({
            variables: {
              input: {
                email,
                organization,
              },
            },
          })
        : getToken({
            variables: {
              input: {
                email,
                password,
                organization,
              },
            },
          });
      if (shouldRememberUser) {
        localStorage.setItem('rememberMe', email);
      } else {
        localStorage.removeItem('rememberMe');
      }
    },
  });
  return (
    <View style={styles.rightWrapper}>
      <View style={styles.rightWrapperBox}>
        <View style={styles.box}>
          <Text
            size="xxxLarge"
            color="#28304e"
            style={{ marginBottom: 50, fontWeight: 400, letterSpacing: 1 }}
          >
            Login to Procost.
          </Text>
          <form onSubmit={formik.handleSubmit}>
            <View>
              <Text size="xxSmall" bold>
                EMAIL ADDRESS
              </Text>
              <TextInput
                data-testid="text-email"
                name="email"
                size="large"
                type="text"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                style={styles.textInput}
              />
              {shouldShowResetPassword && (
                <TouchableOpacity
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                    marginBottom: 30,
                  }}
                  onPress={() => showResetPassword(false)}
                >
                  <Text
                    data-testid="text-returntologin"
                    size="xxSmall"
                    bold
                    color={GREEN}
                  >
                    Return to log in
                  </Text>
                </TouchableOpacity>
              )}
              {!shouldShowResetPassword && (
                <>
                  <Text size="xxSmall" bold style={{ marginTop: 20 }}>
                    PASSWORD
                  </Text>
                  <TextPassword
                    data-testid="text-password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    style={styles.textInput}
                  />
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 20,
                      marginTop: 10,
                    }}
                  >
                    <Checkbox
                      data-testid="checkbox-rememberme"
                      checked={shouldRememberUser}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    >
                      <Text size="xxSmall">Remember me?</Text>
                    </Checkbox>
                    <TouchableOpacity
                      onPress={() => {
                        showResetPassword(true);
                        showError(false);
                      }}
                    >
                      <Text
                        data-testid="text-forgotpassword"
                        size="xxSmall"
                        color={GREEN}
                      >
                        Forgot password?
                      </Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}
              {shouldShowError && (
                <Text size="xxSmall" style={{ marginBottom: 10, color: 'red' }}>
                  {shouldShowError}
                </Text>
              )}
              {loading ? (
                <LoadingIndicator spinning={true} />
              ) : (
                <Button
                  style={{
                    height: 50,
                    boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
                  }}
                  type="primary"
                  asSubmit
                  data-testid="button-submit"
                >
                  <Text size="xxSmall" bold>
                    {shouldShowResetPassword ? 'Reset Password' : 'LOGIN'}
                  </Text>
                </Button>
              )}
            </View>
            <View style={{ marginTop: 20 }}>
              <Text
                data-testid="text-needhelp"
                size="xxSmall"
                color={GREEN}
                link
              >
                Need help?
              </Text>
            </View>
          </form>
        </View>
      </View>
      <View style={styles.footerWrapper}>
        <Text
          size="xxSmall"
          color="#28304e"
          style={{ textDecoration: 'underline', opacity: 0.9 }}
        >
          Privacy Policy
        </Text>
        <View style={styles.dot} />
        <Text
          size="xxSmall"
          color="#28304e"
          style={{ textDecoration: 'underline', opacity: 0.9 }}
        >
          Terms & Conditions
        </Text>
      </View>
    </View>
  );
}

let styles = {
  root: {
    flex: 1,
    flexDirection: 'row' as 'row',
    minWidth: 1250,
    minHeight: 750,
  },
  leftWrapper: {
    flex: 1,
    backgroundColor: '#166b75',
    paddingLeft: 80,
    paddingRight: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightWrapper: {
    flex: 1,
    backgroundColor: WHITE,
    justifyContent: 'center',
    paddingLeft: 100,
    paddingRight: 100,
  },
  rightWrapperBox: {
    flex: 1,
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    flex: 1,
    maxWidth: 500,
    minWidth: 450,
    height: 450,
    justifyContent: 'center',
  },
  headingText: {
    marginBottom: 20,
  },
  button: {
    backgroundColor: GREEN,
    color: WHITE,
    fontFamily: 'Open Sans',
    fontWeight: 700,
    borderRadius: 30,
    padding: 10,
    marginTop: 40,
  },
  dot: {
    backgroundColor: '#28304e',
    opacity: 0.8,
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 10,
  },
  footerWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row' as 'row',
    margin: 50,
    marginTop: 0,
  },
  loading: {
    marginTop: 15,
    alignSelf: 'center',
  },
  rememberMeWrapper: {
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  rememberMe: {
    marginTop: 10,
    marginBottom: 10,
  },
  textInput: {
    marginTop: 10,
    marginBottom: 5,
    height: 50,
    borderRadius: 3,
    backgroundColor: WHITE,
  },
  carouselTitle: {
    marginBottom: 30,
    fontWeight: 400,
    letterSpacing: 1,
    textAlign: 'center' as 'center',
  },
  carouselContent: {
    fontWeight: 300,
    textAlign: 'center' as 'center',
    letterSpacing: 0.4,
    lineHeight: '27px',
  },
};

export default Login;
