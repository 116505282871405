import React, { ComponentProps, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Input as RawInput } from 'antd';
import View from './View';
import { ReactComponent as DollarIcon } from '../assets/dollar-currency-symbol.svg';
import { ReactComponent as RupiahIcon } from '../assets/indonesia-rupiah-currency-symbol.svg';

type Props = ComponentProps<typeof RawInput>;

export default function InputNumber(props: Props) {
  let [isFocused, setIsFocused] = useState(false);
  let { value } = props;
  let formattedValue = useMemo(() => {
    if (value !== undefined && value !== '') {
      //@ts-ignore
      return parseFloat(value).toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return '';
  }, [value]);
  let selectedCurrency = useSelector((state) => state.currency);
  let CurrencyIcon = selectedCurrency === 'USD' ? DollarIcon : RupiahIcon;
  return (
    <View>
      <RawInput
        {...props}
        prefix={
          <CurrencyIcon
            style={{
              width: 16,
              height: 16,
            }}
          />
        }
        type={isFocused ? 'number' : 'text'}
        onFocus={() => setIsFocused(true)}
        value={isFocused ? value : formattedValue}
        onBlur={() => setIsFocused(false)}
      />
    </View>
  );
}
