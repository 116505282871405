import React from 'react';
import { View, Text, Button } from '../core-ui';
import { Modal } from '../components';
import useTranslation from '../helpers/useTranslation';

type Props = {
  onOk: () => void;
  type: string;
  onCancel: () => void;
  isVisible: boolean;
  items: Object[];
  itemName?: string | null | undefined;
  loading: boolean;
  pluralType?: string;
};

export default function ModalDelete({
  onOk,
  type,
  onCancel,
  isVisible,
  items,
  itemName,
  loading,
  pluralType,
}: Props) {
  let t = useTranslation();
  return (
    <Modal
      onCancel={onCancel}
      visible={isVisible}
      style={{ borderRadius: 10 }}
      width={'25%'}
      footer={null}
      centered
      mask
    >
      <View style={{ height: 45 }}>
        <Text>{`${t('Delete')} ${type}`}</Text>
      </View>
      <View>
        {items.length > 1 ? (
          <Text size="xxSmall">{`${t('DeleteThese')} ${items.length} ${
            pluralType ? pluralType.toLowerCase() : `${type.toLowerCase()}`
          }?`}</Text>
        ) : itemName ? (
          <Text size="xxSmall">{`${t(
            'DeleteThis',
          )} ${type.toLowerCase()}: ${itemName}?`}</Text>
        ) : (
          <Text size="xxSmall">{t('DeleteThis')}</Text>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 30,
        }}
      >
        <Button
          data-testid="button-cancel"
          shape="round"
          onPress={onCancel}
          loading={loading}
        >
          {t('Cancel')}
        </Button>
        <Button
          data-testid="button-yes"
          shape="round"
          type="primary"
          style={{ marginLeft: 5 }}
          onPress={onOk}
          loading={loading}
        >
          {t('YesDelete')}
        </Button>
      </View>
    </Modal>
  );
}
