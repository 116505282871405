import React, { ReactNode, ComponentProps } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';

type Props = Omit<
  { children: ReactNode } & ComponentProps<typeof Form.Item>,
  'validateStatus'
> & {
  validateStatus:
    | 'success'
    | 'warning'
    | 'error'
    | 'validating'
    | undefined
    | false
    | '';
};

export default styled(FormValidator)`
  .ant-form-item-control {
    line-height: 30px;
  }
`;

function FormValidator({ validateStatus, ...rest }: Props) {
  let validateStatusTypeEscaped = validateStatus as any;
  return (
    <Form.Item
      style={{
        display: 'flex',
        margin: 0,
      }}
      validateStatus={validateStatusTypeEscaped}
      {...rest}
    />
  );
}
