import React, { useReducer } from 'react';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';

import { View, TouchableOpacity, Text } from '../../core-ui';
import { sidebarReducer } from './utils/reducer';
import navigationInitialState from './utils/initialState';
import useTranslation from '../../helpers/useTranslation';
import getAllowedMenus from './utils/allowedMenus';
import { PALE_GREEN } from '../../constants/colors';
import MenuCollapse from './MenuCollapse';
import MenuItem from './MenuItem';

let Container = styled(View)`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: width 0.3s;
  z-index: 1;
  background-color: ${PALE_GREEN};
  width: ${({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
    isSidebarExpanded ? '260px' : '70px'};

  @media screen and (min-width: 1500px) {
    & {
      width: ${({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        isSidebarExpanded ? '290px' : '70px'};
    }
  }
`;

let MenuSideBar = styled.div`
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(52, 68, 75, 0.93);
    border-radius: 100px;
  }
  overflow-y: auto;
`;

export default function Sidebar() {
  let t = useTranslation();
  let [navigationState, dispatchMenus] = useReducer(
    sidebarReducer,
    navigationInitialState(t)
  );
  let { isSidebarExpanded, menus, isOpen: menuOpen } = navigationState;
  let allowedMenus = getAllowedMenus(menus);
  let menuItems = menus.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <MenuCollapse
          {...menu}
          menuOpen={menuOpen}
          key={menu.id}
          level={1}
          dispatchMenus={dispatchMenus}
          isSidebarExpanded={isSidebarExpanded}
        />
      case 'item':
        return <MenuItem 
          {...menu} 
          menuOpen={menuOpen}
          key={menu.id}
          level={1}
          dispatchMenus={dispatchMenus}
          isSidebarExpanded={isSidebarExpanded}
          />
      default:
        return (
          <Text>Menu Items Error</Text>
        )
    }
  })
  return (
    <Container className="sidebar" isSidebarExpanded={isSidebarExpanded}>
      <View style={styles.dashboardMenuWrapper}>
        <TouchableOpacity
          data-testid="menu-button"
          onPress={() => {
            if (isSidebarExpanded) {
              dispatchMenus({
                type: 'TOGGLE_SIDEBAR',
                isSidebarExpanded: false,
              });
            } else {
              dispatchMenus({
                type: 'TOGGLE_SIDEBAR',
                isSidebarExpanded: true,
              });
            }
          }}
        >
          <MenuOutlined style={styles.dashboardIcon} />
        </TouchableOpacity>
      </View>
      <MenuSideBar>
        {menuItems}
      </MenuSideBar>
    </Container>
  );
}

let styles = {
  dashboardMenuWrapper: {
    flexDirection: 'row' as 'row',
    paddingLeft: 23,
    backgroundColor: '#455a64',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    paddingTop: 4,
    paddingBottom: 4,
    borderColor: 'rgba(17, 24, 39, 0.1)',
  },
  dashboardIcon: {
    fontSize: 22,
    color: '#fafafa',
    paddingTop: 15,
    paddingBottom: 15,
  },
  otherMenuWrapper: {
    marginTop: 10,
    flex: 1,
    overflowY: 'scroll' as 'scroll',
  },
};
