import { useSelector } from 'react-redux';

import { getSelections } from '../helpers';
import { GREEN } from '../constants/colors';

export default function useColorSettings() {
  let { color } = useSelector((state) => state.clientSettings);
  let { selectedClientID } = getSelections();

  return color[selectedClientID] ? color[selectedClientID] : GREEN;
}
