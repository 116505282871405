import React from 'react';
import styled from 'styled-components';

import { WHITE } from '../../../constants/colors';

type DotProps = {
  isActive: boolean;
};

let StyledDot = styled.li`
  height: 12px !important;
  width: 12px !important;
  border-radius: 7.5px;
  background-color: ${WHITE};
  box-shadow: -1px 6px 9px -2px rgba(0, 0, 0, 0.47);
  margin: 0px 7px !important;
  ${({ isActive }: DotProps) =>
    isActive
      ? `
      height: 15px !important;
      width: 15px !important;
      border-radius: 9px;
    `
      : `
      background-color: #DFDFDF;
      border-radius: 9px;
    `}
`;

export default function Dot(dots: JSX.Element[]) {
  return (
    <ul>
      {dots.map(({ props }, i) => {
        let isActive = props.className === 'slick-active';
        return <StyledDot key={i} isActive={isActive} />;
      })}
    </ul>
  );
}
