import React, { ComponentProps } from 'react';
import { Table as RawTable, TablePaginationConfig } from 'antd';
import styled from 'styled-components';

type Props = Omit<ComponentProps<typeof RawTable>, 'pagination'> & {
  pagination?: TablePaginationConfig | false | undefined;
};

let StyledTable = styled(Table)`
  & > td {
    border-bottom-width: 2px !important;
  }
`;

function Table({ className, pagination = undefined, ...props }: Props) {
  pagination =
    pagination === undefined
      ? {
          position: ['topRight'],
          showSizeChanger: true,
          defaultPageSize: 15,
          pageSizeOptions: ['10', '15', '25'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }
      : pagination;
  return (
    <RawTable
      pagination={pagination}
      rowClassName={() => String(className)}
      {...props}
    />
  );
}

export default StyledTable;
