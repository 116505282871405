import React, { CSSProperties } from 'react';

import { Icon as LegacyIcon } from '@ant-design/compatible';

type Props = {
  name: string;
  size?: number;
  color?: string;
  style?: CSSProperties;
  onClick?: () => void;
  theme?: any;
};

function Icon({
  name,
  color,
  size,
  style,
  onClick,
  theme,
  ...otherProps
}: Props) {
  return (
    <LegacyIcon
      type={name}
      theme={theme}
      style={{
        color,
        fontSize: size,
        ...style,
      }}
      onClick={onClick}
      {...otherProps}
    />
  );
}

export default Icon;
