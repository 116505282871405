// Taken from previous Webcert project
import React from 'react';

import ReactGauge from './RawGaugeChart';

let getMarks = (min = 0, max = min, interval = 5) => {
  let marks: number[] = [];

  for (let i = min - 20; i <= max + 20; i += interval) {
    marks.push(i);
  }

  return marks;
};

let getArrowValue = (value: number, from: number, divider: number) => {
  let arrowValue = (value + 20 - from) / divider;

  if (arrowValue < 0) {
    return 0.0;
  }

  if (arrowValue > 1) {
    return 1.0;
  }

  return arrowValue;
};

type Props = {
  from: number;
  to: number;
  value: number;
  reverseColor: boolean;
};

export default function GaugeChart(props: Props) {
  let { from = 0, to = 0, reverseColor = false, value = 0 } = props;
  let isInvalid = from > to;

  from = from < -100 ? -100 : from;
  to = to > 100 ? 100 : to;

  let interval = 5;
  let marks = getMarks(from, to, interval);
  let divider = (marks.length - 1) * interval;
  let maxValue = (to + 20 - from) / divider;
  let arrowValue = getArrowValue(value, from, divider);

  return (
    <ReactGauge
      isInnerNumbers={false}
      aperture={180}
      arcStrokeWidth={25}
      tickOffset={14}
      marks={marks}
      svgContainerHeight={250}
      radius={150}
      arrowValue={arrowValue}
      ranges={[
        {
          start: 0,
          end: 20 / divider,
          color: reverseColor ? '#ff0000' : '#6ad868',
        },
        {
          start: 20 / divider,
          end: maxValue,
          color: '#e5e23d',
        },
        {
          start: maxValue,
          end: 1,
          color: reverseColor ? '#3ee53b' : '#ff0000',
        },
      ]}
      isInvalid={isInvalid}
    />
  );
}
