import navigationInitialState from './initialState';

export type MenuAction =
  | {
      type: 'MENU_OPEN';
      menuId: string;
    }
  | {
      type: 'RESET_STATE';
      payload: {
        t: (key: string) => string;
      };
    }
  | { type: 'TOGGLE_SIDEBAR'; isSidebarExpanded?: boolean }
  | {
      type: 'CHANGE_LANGUAGE';
      payload: {
        t: (key: string) => string;
      };
    };

type MenuState = {
  isSidebarExpanded: boolean;
  isOpen: string[], // for active default menu
  defaultId: string,
  menus: Array<Menu>;
};

export type Menu = {
  id: string;
  name: string;
  Icon: React.FC;
  type: string;
  link?: string;
  children?: Array<Menu>;
  resource?: {
    identifier:string,
    type: string
  }
};


export function sidebarReducer(
  navigationState: MenuState,
  action: MenuAction,
): MenuState {
  let id;
  switch (action.type) {
    case 'TOGGLE_SIDEBAR': {
      return {
        ...navigationState,
        isSidebarExpanded: action.isSidebarExpanded
          ? action.isSidebarExpanded
          : !navigationState.isSidebarExpanded,
      };
    }
    case 'MENU_OPEN': {
      id = action.menuId;
      return {
        ...navigationState,
        isOpen: [id],
      };
    }
    case 'CHANGE_LANGUAGE': {
      return {
        ...navigationState,
        menus: navigationState.menus.map((menu) => ({
          ...menu,
          name: action.payload.t(menu.name),
          children: menu?.children?.map((subMenu) => ({
            ...subMenu,
            name: action.payload.t(subMenu.name),
          })),
        })),
      };
    }
    case 'RESET_STATE': {
      let { menus } = navigationInitialState(action.payload.t);
      return {
        ...navigationState,
        isOpen: [],
        menus: menus,
      };
    }
    default:
      return navigationState;
  }
}