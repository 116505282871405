type Type = {
  Page: 'PAGE';
  Control: 'CONTROL';
};

const ResourceType: Type = {
  Page: 'PAGE',
  Control: 'CONTROL',
};

export default ResourceType;
