import React, { useState, useEffect, ReactElement } from 'react';
import { Avatar, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Form, FormikState } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

import { View, Text, Icon, Select, Button } from '../../core-ui';
import { Popover } from '../../components';
import SelectProjectPopover from './SelectProjectPopover';
import SearchProject from './SearchProject';
import Reminders from './Reminders';
import UserProfile from './UserProfile';
import { PALE_GREEN, LIGHTEST_GREY } from '../../constants/colors';
import {
  GET_CLIENTS,
  GET_PROJECTS,
  GET_CURRENT_LICENSEE,
  GET_PERSON,
} from '../../gql';
import { getSelections } from '../../helpers';
import { UPDATE_PERSON_SELECTION } from './gql';

import { PassportOrganizationQl } from '../../types';

const LANGUAGE_ID = '9ac73f18-de57-439e-892d-317aa6e57226';

type InitialValues = {
  selectedClientID: Maybe<ID>;
  selectedProgrammeID: Maybe<ID>;
  selectedRegionID: Maybe<ID>;
  selectedProjectID: Maybe<ID>;
};

function Header({ history }) {
  let currentUser = useSelector((state) => state.currentUser);
  let dispatch = useDispatch();
  let [isPopoverVisible, setPopoverVisibility] = useState<boolean>(false);
  let {
    selectedClientID,
    selectedProgrammeID,
    selectedRegionID,
    selectedProjectID,
    selectClientID,
    selectProgrammeID,
    selectRegionID,
    selectProjectID,
    selectCertificationID,
  } = getSelections();

  let initialValues: InitialValues = {
    selectedClientID,
    selectedProgrammeID,
    selectedRegionID,
    selectedProjectID,
  };

  let clientsQuery = useQuery(GET_CLIENTS, {
    variables: {
      first: 1000,
    },
  });
  let clients = clientsQuery.data?.clients?.items ?? [];

  let projectsQuery = useQuery(GET_PROJECTS, {
    variables: {
      limit: {
        first: 1000,
      },
    },
  });

  let projects = projectsQuery.data?.projects.items || [];
  let selectedProject = projects.find(({ id }) => id === selectedProjectID);

  let personQuery = useQuery(GET_PERSON, {
    variables: {
      personID: currentUser?.userID,
    },
  });
  let person = personQuery.data?.person;

  let currentLicenseeQuery = useQuery(GET_CURRENT_LICENSEE);
  let currentLicensee: PassportOrganizationQl =
    currentLicenseeQuery.data?.currentLicense;

  let [updateSelectedProject] = useMutation(UPDATE_PERSON_SELECTION);
  useEffect(() => {
    if (personQuery.networkStatus === 7) {
      let savedSelection = JSON.parse(person.saveSelection || '{}');
      if (Object.keys(savedSelection).length) {
        selectProjectID(savedSelection.projectId);
        selectRegionID(savedSelection.regionId);
        selectProgrammeID(savedSelection.programmeId);
        selectClientID(savedSelection.clientId);
      }
    }
  }, [personQuery.loading]);

  useEffect(() => {
    let setCurrencyCode = currentLicensee?.currencyCode;
    dispatch({
      type: 'currency/CHANGE_CURRENCY',
      payload: {
        selectedCurrency: setCurrencyCode || 'USD',
      },
    });
  }, [currentLicensee]);

  useEffect(() => {
    let setLanguage = person?.language?.id;
    dispatch({
      type: 'i18n/SET_LANGUAGE',
      payload: {
        languageChosen: setLanguage === LANGUAGE_ID ? 'en' : 'id',
      },
    });
  }, [personQuery.loading]);

  return (
    <View style={styles.root} className="header">
      <View style={styles.leftWrapper}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            size={40}
            src={currentLicensee?.logoPath || ''}
            shape="square"
            style={styles.logo}
          />
          <Select
            data-testid="select-clientdashboard"
            showSearch
            placeholder="Select a client"
            loading={clientsQuery.loading}
            value={
              !clientsQuery.loading ? (selectedClientID as string) : undefined
            }
            optionFilterProp="children"
            onChange={(clientID) => {
              if (clientID !== selectedClientID) {
                updateSelectedProject({
                  variables: {
                    selection: JSON.stringify({
                      clientId: clientID,
                    }),
                  },
                }).then(() => {
                  selectClientID(String(clientID));
                  selectProgrammeID(undefined);
                  selectRegionID(undefined);
                  selectProjectID(undefined);
                });
              }
            }}
            filterOption={(input, option) => {
              let optionValue = option?.props?.children as ReactElement;
              let text = optionValue?.props?.children as string;
              return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            style={{
              width: 300,
            }}
          >
            {clients.map(({ id, name }, i: number) => (
              <Select.Option value={id} key={i} data-testid={'option-' + name}>
                <Text size="xxSmall">{name}</Text>
              </Select.Option>
            ))}
          </Select>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, formikBag) => {
              updateSelectedProject({
                variables: {
                  selection: JSON.stringify({
                    projectId: values.selectedProjectID,
                    regionId: values.selectedRegionID,
                    programmeId: values.selectedProgrammeID,
                    clientId: values.selectedClientID,
                  }),
                },
              }).then(() => {
                selectProgrammeID(values.selectedProgrammeID);
                selectRegionID(values.selectedRegionID);
                selectProjectID(values.selectedProjectID);
                selectCertificationID(null);
                formikBag.resetForm(
                  values as Partial<FormikState<InitialValues>>,
                );
                setPopoverVisibility(false);
                message.success('Successfully set project!');
              });
            }}
          >
            {(props) => (
              <Popover
                visible={isPopoverVisible}
                content={
                  <Form onSubmit={props.handleSubmit}>
                    <SelectProjectPopover
                      setPopoverVisibility={setPopoverVisibility}
                    />
                  </Form>
                }
                trigger="click"
                onVisibleChange={(visible) => {
                  setPopoverVisibility(visible);
                }}
              >
                <Button
                  data-testid="button-select-project"
                  type="dashed"
                  shape="round"
                  icon={<DownOutlined />}
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                  }}
                >
                  {selectedProject ? (
                    <Text size="xxSmall">{selectedProject.name}</Text>
                  ) : projectsQuery.loading ? (
                    <Icon name="loading" style={{ marginLeft: 20 }} />
                  ) : (
                    <Text size="xxSmall">Select a project</Text>
                  )}
                </Button>
              </Popover>
            )}
          </Formik>
        </View>
      </View>
      <View style={styles.rightWrapper}>
        <SearchProject
          projects={projects}
          projectsLoading={projectsQuery.loading}
          updateSelectedProject={updateSelectedProject}
        />
        <Reminders history={history} />
        <UserProfile history={history} person={person} />
      </View>
    </View>
  );
}

export let HeaderIcon = styled(Icon)`
  padding: 6px;
  &:active {
    padding: 6px;
    border-radius: 100%;
    background-color: #dddfe0;
  }
`;

let styles = {
  root: {
    flexDirection: 'row' as 'row',
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: LIGHTEST_GREY,
    zIndex: 1,
  },
  leftWrapper: {
    alignItems: 'center',
    flexDirection: 'row' as 'row',
    flex: 1,
  },
  rightWrapper: {
    alignItems: 'center',
    flexDirection: 'row' as 'row',
  },
  sidebarHeader: {
    backgroundColor: PALE_GREEN,
    padding: 15,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
  },
  actionHeaderWrapper: {
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  logo: {
    marginRight: 15,
  },
};

export default withRouter(Header);
