import { useMemo } from 'react';

import useResources from '../../../hooks/useResources';

export default function allowedMenus(menus) {
  let { allowedResources, isLoading } = useResources();

  let mapAllowedMenus = (menus) => menus.map((menu) => {
    return {
      ...menu,
      children: menu.type === 'collapse' ? mapAllowedMenus(menu.children) : menu.children?.filter(({ resource, id }) => {
        if (allowedResources.length) {
          return Boolean(
            allowedResources.find(
              (allowedResource) =>
                allowedResource.identifier === resource.identifier,
            ),
          );
        }
        return true;
      }),
    }})
  
  let allowedMenus = useMemo(() => {
    return mapAllowedMenus(menus);
  }, [allowedResources, isLoading, menus]);

  if (isLoading) {
    allowedMenus = [];
  }

  return allowedMenus;
}
