// TODO: Need better way to validate token
export default function validateToken(error) {
  let isTokenValid = true;
  if (error) {
    let networkError: any = error.networkError;
    let statusCode = networkError?.statusCode;
    if (statusCode === 401) {
      isTokenValid = false;
      localStorage.removeItem('session');
    }
  }
  return isTokenValid;
}
