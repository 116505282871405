import gql from 'graphql-tag';

export let LOGIN = gql`
  query Login($input: any) {
    login(input: $input)
      @rest(type: "Auth", path: "/account/login", method: "POST") {
      email
      token
    }
  }
`;

export let RESET_PASSWORD = gql`
  query ResetPassword($input: any) {
    resetPassword(input: $input)
      @rest(
        type: "ResetPassword"
        path: "/account/forgotpassword"
        method: "POST"
      )
  }
`;
