import React, { ReactNode, CSSProperties as StyleSheet } from 'react';
import styled, { CSSProperties } from 'styled-components';

let Button = styled.div`
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  transition: opacity 250ms ease;
  opacity: 1;
  cursor: pointer;
  user-select: none;

  &:active {
    opacity: 0.5;
  }
`;

type Props = {
  onPress?: () => void;
  style?: CSSProperties | StyleSheet;
  children?: ReactNode;
};

type TouchableOpacityProps = Props &
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>;

function TouchableOpacity({
  onPress,
  children,
  ...otherProps
}: TouchableOpacityProps) {
  return (
    <Button onClick={onPress} {...otherProps}>
      {children}
    </Button>
  );
}

export default TouchableOpacity;
