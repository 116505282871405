import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import getSelections from '../helpers/getSelections';
import { GET_PERSON } from '../gql';

export default function useResources() {
  let currentUser = useSelector((state) => state.currentUser);
  let { selectedClientID, selectedProjectID } = getSelections();
  let personQuery = useQuery(GET_PERSON, {
    variables: {
      personID: currentUser?.userID,
    },
  });

  let person = personQuery.data?.person;

  let clients = person?.clients;
  let organization = person?.organization;
  let projects = person?.projects;

  let resourceList = useMemo(() => {
    let list: any = [];

    if (clients && clients.length) {
      clients
        .filter(({ id }) => id === selectedClientID)
        .forEach(({ roles }) => {
          roles.forEach(({ permissions }) => {
            permissions.forEach(({ resources }) => {
              resources.forEach((resource) => list.push(resource));
            });
          });
        });
    }

    if (organization) {
      organization.roles.forEach(({ permissions }) => {
        permissions.forEach(({ resources }) => {
          resources.forEach((resource) => list.push(resource));
        });
      });
    }

    if (projects && projects.length) {
      projects.forEach(({ roles }) => {
        roles.forEach(() => ({ permissions }) => {
          permissions.forEach(({ resources }) => {
            resources.forEach((resource) => list.push(resource));
          });
        });
      });
    }

    return list;
  }, [selectedClientID, selectedProjectID, clients, organization, projects]);

  return {
    allowedResources: resourceList,
    isLoading: personQuery.loading,
  };
}
