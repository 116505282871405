import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { useField, useFormikContext } from 'formik';

import { View, Button, Text, Select } from '../../core-ui';
import { LIGHTER_GREY } from '../../constants/colors';
import {
  GetProgrammesQuery,
  GetProgrammesQueryVariables,
  ProgrammeQl,
} from '../../types';
import { GET_PROGRAMMES, GET_PROJECTS, GET_REGIONS } from '../../gql';

type Props = {
  setPopoverVisibility: (isVisible: boolean) => void;
};

export default function SelectProjectPopover(props: Props) {
  let formik = useFormikContext();
  let [, clientMeta] = useField('selectedClientID');
  let [, programmeMeta, programmeHelpers] = useField('selectedProgrammeID');
  let [, regionMeta, regionHelpers] = useField('selectedRegionID');
  let [, projectMeta, projectHelpers] = useField('selectedProjectID');

  let programmesQuery = useQuery<
    GetProgrammesQuery,
    GetProgrammesQueryVariables
  >(GET_PROGRAMMES, {
    variables: {
      limit: {
        first: 1000,
      },
    },
  });
  let programmes = programmesQuery.data?.programmes?.items || [];
  let filteredProgrammes = programmes.filter(
    (programme) => programme?.clientId === clientMeta.value,
  ) as ProgrammeQl[];

  let regionsQuery = useQuery(GET_REGIONS, {
    variables: {
      limit: {
        first: 1000,
      },
    },
  });
  let regions = regionsQuery.data?.regions.items || [];
  let filteredRegions = regions.filter(
    ({ clientId }) => clientId === clientMeta.value,
  );

  let projectsQuery = useQuery(GET_PROJECTS, {
    variables: {
      limit: {
        first: 1000,
      },
    },
  });
  let projects = projectsQuery.data?.projects.items || [];
  let filteredProjects = projects.filter(
    ({ clientId, regionId, programmeId }) =>
      clientId === clientMeta.value &&
      regionId === regionMeta.value &&
      programmeId === programmeMeta.value,
  );

  return (
    <View style={styles.root}>
      <View style={{ ...styles.inputWrapper, paddingTop: 0 }}>
        <Text size="xxSmall" bold>
          Programme
        </Text>
        <Select
          data-testid="select-programmedashboard"
          disabled={!clientMeta.value}
          showSearch
          placeholder="Select a programme"
          loading={programmesQuery.loading}
          value={!programmesQuery.loading ? programmeMeta.value : undefined}
          style={styles.selectWrapper}
          optionFilterProp="children"
          onChange={(programmeID) => {
            programmeHelpers.setValue(String(programmeID));
          }}
          filterOption={(input, option) => {
            let optionValue = option?.props?.children as ReactElement;
            let text = optionValue?.props?.children as string;
            return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {filteredProgrammes.map(({ id, name }, i: number) => (
            <Select.Option value={id} key={i}>
              <Text size="xxSmall" data-testid={'option-programme-' + name}>
                {name}
              </Text>
            </Select.Option>
          ))}
        </Select>
      </View>
      <View style={styles.divider} />
      <View style={styles.inputWrapper}>
        <Text size="xxSmall" bold>
          Region
        </Text>
        <Select
          data-testid="select-regiondashboard"
          disabled={!clientMeta.value}
          showSearch
          placeholder="Select a region"
          loading={regionsQuery.loading}
          value={!regionsQuery.loading ? regionMeta.value : undefined}
          style={styles.selectWrapper}
          optionFilterProp="children"
          onChange={(regionID) => {
            regionHelpers.setValue(String(regionID));
          }}
          filterOption={(input, option) => {
            let optionValue = option?.props?.children as ReactElement;
            let text = optionValue?.props?.children as string;
            return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {filteredRegions.map(({ id, name }, i: number) => (
            <Select.Option
              value={id}
              key={i}
              data-testid={'option-region-' + name}
            >
              <Text size="xxSmall">{name}</Text>
            </Select.Option>
          ))}
        </Select>
      </View>
      <View style={styles.divider} />
      <View style={styles.inputWrapper}>
        <Text size="xxSmall" bold>
          Project
        </Text>
        <Select
          data-testid="select-projectdashboard"
          disabled={!programmeMeta.value || !regionMeta.value}
          showSearch
          placeholder="Select a project"
          loading={projectsQuery.loading}
          value={!projectsQuery.loading ? projectMeta.value : undefined}
          style={styles.selectWrapper}
          optionFilterProp="children"
          onChange={(projectID) => {
            projectHelpers.setValue(String(projectID));
          }}
          filterOption={(input, option) => {
            let optionValue = option?.props?.children as ReactElement;
            let text = optionValue?.props?.children as string;
            return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {filteredProjects.map(({ id, name }, i: number) => (
            <Select.Option
              value={id}
              key={i}
              data-testid={'option-project-' + name}
            >
              <Text size="xxSmall">{name}</Text>
            </Select.Option>
          ))}
        </Select>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          data-testid="button-canceldashboard"
          onPress={() => {
            formik.handleReset();
            props.setPopoverVisibility(false);
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid="button-selectdashboard"
          style={{ marginLeft: 15 }}
          disabled={
            !programmeMeta.value ||
            !regionMeta.value ||
            !projectMeta.value ||
            !formik.dirty
          }
          type="primary"
          asSubmit
        >
          Select
        </Button>
      </View>
    </View>
  );
}

let styles = {
  root: {
    width: 350,
    paddingBottom: 5,
  },
  inputWrapper: {
    paddingTop: 10,
    paddingBottom: 15,
  },
  selectWrapper: {
    marginTop: 10,
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: LIGHTER_GREY,
    borderStyle: 'dashed',
  },
};
