import React, { ReactNode } from 'react';
import RawSlider from 'react-slick';

import SlickDot from './SlickDot';

const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 15000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  appendDots: SlickDot,
};

type Props = {
  children: ReactNode;
};

export default function Slider({ children }: Props) {
  return <RawSlider {...SETTINGS}>{children}</RawSlider>;
}
