import React, { ComponentProps } from 'react';
import { Checkbox as RawCheckbox } from 'antd';

type Props = ComponentProps<typeof RawCheckbox>;

export default function Checkbox(props: Props) {
  return <RawCheckbox {...props} />;
}

Checkbox.Group = RawCheckbox.Group;
