import { batch } from 'react-redux';

export default function setTranslations(data, dispatch) {
  let translationsArray = data?.translationTexts ?? [];
  let translations = translationsArray.reduce((acc, { code, text }) => {
    return {
      ...acc,
      [code]: JSON.parse(text),
    };
  }, {});

  if (Object.keys(translations).length) {
    batch(() => {
      dispatch({
        type: 'i18n/INIT_TRANSLATIONS',
        payload: translations,
      });

      //TODO: set language specified by backend otherwise default is bahasa Indonesia
      dispatch({
        type: 'i18n/SET_LANGUAGE',
        payload: {
          languageChosen: 'en',
        },
      });
    });
  }
}
